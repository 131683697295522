import { FC, useRef, useEffect, useCallback, Fragment, useState, useContext } from "react";
import clsx from "clsx";
import { LessonBuilderPageSnippetModel } from "../../../models/lessonbuilder/pageitems/LessonBuilderPageSnippetModel";
import { createMarkup } from "../../../utils/Helpers";
import { useHighlighting } from "../../../hooks/useHighlighting";
import { Badge, Button, CardBody, CardFooter, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { HighlightingModal } from "../snippets/HighlightingModal";
import { ExpansionModal } from "../snippets/ExpansionModal";
import { MultiHighlightingModal } from "../snippets/MultiHighlightingModal";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";

type Props = {
    model: LessonBuilderPageSnippetModel;
};

export const LessonBuilderPageSnippet: FC<Props> = (props: Props) => {
    const { model } = props;
    const { JsonHighlighting, IsExample } = model;

    const div = useRef<HTMLDivElement>(null);

    const { setHighlighting: setHighlight, clearHighlighting } = useHighlighting(div);
    const { updatePageItem } = useContext(LessonBuilderContext);

    const [applyingHighlighting, setApplyingHighlighting] = useState(false);
    const [applyingExpansion, setApplyingExpansion] = useState(false);
    const [applyingMultiHighlighting, setApplyingMultiHighlighting] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(prevState => !prevState);
    };

    const loadData = useCallback(() => {
        clearHighlighting();

        if (JsonHighlighting) {
            setHighlight(JsonHighlighting);
        }
    }, [JsonHighlighting, clearHighlighting, setHighlight]);

    const revertSnippet = (id: number) => {
        LessonBuilderService.revertSnippet(id).then(x => {
            updatePageItem(x.Data);
        });
    };

    const convertSnippet = (id: number, exerciseType: string) => {
        LessonBuilderService.convertSnippet(id, exerciseType).then(x => {
            updatePageItem(x.Data);
        });
    };

    useEffect(loadData, [JsonHighlighting]);

    return (
        <Fragment>
            <CardBody>
                {model.ExerciseType && (
                    <div className="mb-3">
                        <Badge color="primary">{model.ExerciseType} exercise</Badge>
                    </div>
                )}
                <div
                    ref={div}
                    className={clsx("snippet", IsExample ? "snippet-example" : "snippet-primary")}
                    dangerouslySetInnerHTML={createMarkup(model.Body)}
                ></div>
            </CardBody>
            <CardFooter>
                {model.ExerciseType ? (
                    <Button className="mr-1" onClick={() => revertSnippet(model.ID)}>
                        Revert activity
                    </Button>
                ) : (
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="up">
                        <DropdownToggle>Convert</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => setApplyingHighlighting(true)}>
                                Highlighting activity
                            </DropdownItem>

                            <DropdownItem onClick={() => setApplyingMultiHighlighting(true)}>
                                Multi highlighting
                            </DropdownItem>

                            <DropdownItem onClick={() => setApplyingExpansion(true)}>Expansion activity</DropdownItem>

                            <DropdownItem onClick={() => convertSnippet(model.ID, "Memory")}>
                                Memory activity
                            </DropdownItem>

                            <DropdownItem onClick={() => convertSnippet(model.ID, "Proofing")}>
                                Proofing activity
                            </DropdownItem>

                            {model.IsShuffleable && (
                                <DropdownItem onClick={() => convertSnippet(model.ID, "Shuffle")}>
                                    Shuffle activity
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                )}
            </CardFooter>

            {applyingHighlighting && (
                <HighlightingModal
                    id={model.ID}
                    onCompleted={() => setApplyingHighlighting(false)}
                    body={model.Body}
                    isExample={model.IsExample}
                />
            )}

            {applyingExpansion && (
                <ExpansionModal
                    id={model.ID}
                    onCompleted={() => setApplyingExpansion(false)}
                    body={model.Body}
                    isExample={model.IsExample}
                />
            )}

            {applyingMultiHighlighting && (
                <MultiHighlightingModal
                    id={model.ID}
                    body={model.Body}
                    onCompleted={() => setApplyingMultiHighlighting(false)}
                />
            )}
        </Fragment>
    );
};
