import * as yup from "yup";

export interface LessonTopicPageInstructionModel {
    Body: string;
    CollapsedText?: string;
}

export const LessonTopicPageInstructionModelValidationRules = {
    Body: yup.string().required(),
    CollapsedText: yup.string().optional(),
}