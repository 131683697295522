import { FC } from "react";
import { DeepMap, FieldError } from "@hookform/error-message/dist/types";
import { LessonTopicPageTextboxModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxModel";
import { nameof } from "../../../utils/Helpers";
import { Checkbox } from "../Checkbox";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLCheckboxElement>(refOrValidationOptions: HTMLCheckboxElement) => void | ((ref: HTMLCheckboxElement | null) => void);
};

export const FieldLessonTopicPageTextboxIsFeed: FC<Props> = (props: Props) => {
    const { errors, register } = props;

    const nameIsFeed = nameof<LessonTopicPageTextboxModel>("IsFeed");

    return (
        <Checkbox
            name={nameIsFeed}
            label="Has feed"
            register={register}
            errors={errors}
            helperText="This allows users to view and comment on other users' responses"
        />
    );
};
