import { FC, useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, Container, Input, Table } from "reactstrap";
import queryString from "query-string";
import { ImageBrowserItem } from "../../models/images/ImageBrowserModel";
import { useFormState } from "../../hooks/useFormState";
import { ImagesService } from "../../services/ImagesService";
import { Loading } from "../../components/Loading";
import { Constants } from "../../utils/Constants";

export const ImagesPage: FC = () => {
    const parsed = queryString.parse(window.location.search);
    const { webUrl } = Constants;
    const { loading, setLoading } = useFormState(true);

    const [query, setQuery] = useState("");
    const [images, setImages] = useState<Array<ImageBrowserItem>>([]);
    const [filteredImages, setFilteredImages] = useState<Array<ImageBrowserItem>>([]);

    const selectImage = (url: string) => {
        if (parsed && parsed.CKEditorFuncNum) {
            const funcNumber = +parsed.CKEditorFuncNum;

            window.opener.CKEDITOR.tools.callFunction(funcNumber, url);
            window.close();
        }
    };

    const loadData = useCallback(() => {
        ImagesService.getImages().then(x => {
            setImages(x.Items);
            setLoading(false);
        });
    }, [setLoading]);

    useEffect(loadData, []);

    useEffect(() => {
        if (query) {
            const q = query.toLowerCase();

            const newImages = images.filter(i => i.Name.toLowerCase().indexOf(q) > -1);

            setFilteredImages(newImages);
        } else {
            setFilteredImages(images);
        }
    }, [images, query]);

    return (
        <Container style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
            {loading ? (
                <Loading />
            ) : (
                <Card className="my-5">
                    <CardBody>
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <div>
                                <Input
                                    placeholder="Filter images"
                                    type="search"
                                    onChange={e => {
                                        setQuery(e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <Button
                                    tag="a"
                                    href={`${webUrl}/admin/siteimages/create`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Add image
                                </Button>
                            </div>
                        </div>

                        <Table hover>
                            <tbody>
                                {filteredImages &&
                                    filteredImages.map(i => (
                                        <tr key={i.ID}>
                                            <td>
                                                <Button onClick={() => selectImage(i.ImageUrl)}>Use</Button>
                                            </td>
                                            <td>{i.Name}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            )}
        </Container>
    );
};
