import * as yup from "yup";

export interface LessonTopicPageVideoModel {
    VideoID: string;
}

export const LessonTopicPageVideoModelValidationRules = {
    VideoID: yup
        .string()
        .required()
        .matches(/^[A-Za-z0-9-_]{11}$/, "Must be an 11 letter YouTube video ID"),
};
