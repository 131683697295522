import { useEffect, useRef, useState } from "react";
import { DeepMap, FieldError, NestedValue, NonUndefined } from "@hookform/error-message/dist/types";
import { Hidden } from "./Hidden";
import { ImageListItem } from "../../models/ImageListItem";
import { Button } from "reactstrap";
import { UnpackNestedValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { DeepPartial, LiteralToPrimitive } from "react-hook-form/dist/types/utils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { LessonBuilderService } from "../../services/LessonBuilderService";
import { CustomErrors } from "../CustomErrors";

export const ImageSelect = (props: {
    name: string;
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLInputElement>(
        refOrValidationOptions: HTMLInputElement,
    ) => void | ((ref: HTMLInputElement | null) => void);
    label: string;
    setValue(
        name: string,
        value?: NonUndefined<number> extends NestedValue<infer U>
            ? U
            : UnpackNestedValue<DeepPartial<LiteralToPrimitive<number>>>,
        options?: SetValueConfig,
    ): void;
    initialSelectedImage?: ImageListItem;
}) => {
    const { name, register, label, setValue, initialSelectedImage, errors } = props;

    const [selectedImage, setSelectedImage] = useState<ImageListItem>();

    const [isLoading, setIsLoading] = useState(false);

    const [options, setOptions] = useState<Array<ImageListItem>>([]);

    const typeaheadRef = useRef(null);

    const selectImage = (image: ImageListItem) => {
        setValue(name, image.ID);
        setSelectedImage(image);
    };

    const removeImage = () => {
        setValue(name, undefined);
        setSelectedImage(undefined);
        (typeaheadRef.current as unknown as any).clear();
    };

    // load selected image on edit
    useEffect(() => {
        if (initialSelectedImage) {
            setSelectedImage(initialSelectedImage);
        }
    }, [initialSelectedImage]);

    return (
        <div className="mb-3">
            <div className="mb-2">{label}</div>

            <div className="row">
                <div className="col-12">
                    <AsyncTypeahead
                        ref={typeaheadRef}
                        id="image-select"
                        isLoading={isLoading}
                        options={options}
                        placeholder="Type to search image library"
                        filterBy={() => true}
                        labelKey="Name"
                        onChange={obj => {
                            if (obj.length) {
                                const [firstItem] = obj;

                                const img = firstItem as ImageListItem;
                                selectImage(img);
                            }
                        }}
                        onSearch={query => {
                            setIsLoading(true);

                            LessonBuilderService.getImages(query).then(x => {
                                setOptions(x);

                                setIsLoading(false);
                            });
                        }}
                    />

                    <div>
                        {selectedImage && (
                            <Button type="button" className="mt-3" onClick={removeImage}>
                                Remove image
                            </Button>
                        )}
                    </div>
                </div>

                <div className="col-12">
                    {selectedImage != null ? (
                        <div>
                            <img alt="" src={selectedImage.ImageUrl} className="img-fluid" />
                        </div>
                    ) : (
                        <div>No image selected</div>
                    )}
                </div>
            </div>

            <CustomErrors errors={errors} name={name} />

            <Hidden name={name} register={register} />
        </div>
    );
};
