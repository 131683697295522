import { FC } from "react";
import WrotevoteLogo from "../../assets/img/wrotevote/wrotevote-logo.svg";
import clsx from "clsx";

type Props = {
    size?: "lg" | "sm";
};

export const WrotevoteLogoHeader: FC<Props> = (props: Props) => {
    const { size } = props;

    return (
        <h1 className={clsx("wrotevote-logo", size === "sm" && "wrotevote-logo--small")}>
            <img alt="" src={WrotevoteLogo} />
        </h1>
    );
};

WrotevoteLogoHeader.defaultProps = {
    size: "sm",
};
