import { FC } from "react";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { LessonTopicPageVideoModel } from "../../../models/crud/lessontopicpagevideos/LessonTopicPageVideoModel";
import { nameof } from "../../../utils/Helpers";
import { Textbox } from "../Textbox";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLInputElement>(
        refOrValidationOptions: HTMLInputElement,
    ) => void | ((ref: HTMLInputElement | null) => void);
};

export const FieldLessonTopicPageVideoID: FC<Props> = (props: Props) => {
    const { errors, register } = props;

    const nameVideoID = nameof<LessonTopicPageVideoModel>("VideoID");

    return <Textbox name={nameVideoID} register={register} errors={errors} label="Video ID" />;
};
