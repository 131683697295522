import { FC } from "react";
import { DeepMap, FieldError } from "@hookform/error-message/dist/types";
import { LessonTopicPageTextboxModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxModel";
import { nameof } from "../../../utils/Helpers";
import { Checkbox } from "../Checkbox";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLCheckboxElement>(refOrValidationOptions: HTMLCheckboxElement) => void | ((ref: HTMLCheckboxElement | null) => void);
};

export const FieldLessonTopicPageTextboxIsCombinedFromAllPrevious: FC<Props> = (props: Props) => {
    const { errors, register } = props;

    const nameIsCombinedFromAllPrevious = nameof<LessonTopicPageTextboxModel>("IsCombinedFromAllPrevious");

    return (
        <Checkbox
            name={nameIsCombinedFromAllPrevious}
            label="Whole - All previous replies"
            register={register}
            errors={errors}
            helperText="Preloads with all previous combinable textbox replies"
        />
    );
};
