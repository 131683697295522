import { FC, useCallback, useEffect, useState } from "react";
import { Page } from "../Page";
import { Loading } from "../../components/Loading";
import { useFormState } from "../../hooks/useFormState";
import { ReactSortable } from "react-sortablejs";
import { LessonBuilderItem } from "../../components/lessonbuilder/LessonBuilderItem";
import { PageFadeAnimation } from "../../components/animation/PageFadeAnimation";
import { LessonBuilderTopicComponent } from "../../components/lessonbuilder/LessonBuilderTopic";
import { Container, Row, Col, Card, CardBody, ButtonGroup, Button } from "reactstrap";
import { AddSnippetModal } from "../../components/lessonbuilder/snippets/AddSnippetModal";
import { AddTextboxModal } from "../../components/lessonbuilder/textboxes/AddTextboxModal";
import { AddInstructionModal } from "../../components/lessonbuilder/instructions/AddInstructionModal";
import { LessonBuilderService } from "../../services/LessonBuilderService";
import { LessonBuilderBreadcrumbs } from "../../components/lessonbuilder/LessonBuilderBreadcrumbs";
import { LessonBuilderPageItemsSortModel } from "../../models/lessonbuilder/pageitems/LessonBuilderPageItemsSortModel";
import { LessonBuilderContext, useLessonBuilder } from "../../hooks/useLessonBuilder";
import { LessonTopicPagesSortModel } from "../../models/crud/lessontopicpages/LessonTopicPagesSortModel";
import { AddTopicModal } from "../../components/lessonbuilder/topics/AddTopicModal";
import { Constants } from "../../utils/Constants";
import { AddVideoModal } from "../../components/lessonbuilder/videos/AddVideoModal";
import { AddImageModal } from "../../components/lessonbuilder/images/AddImageModal";

type Props = {
    id: string;
};

export const LessonBuilderPage: FC<Props> = (props: Props) => {
    const id = +props.id;
    const { loading, setLoading } = useFormState(true);
    const { webUrl } = Constants;

    const lessonBuilderContext = useLessonBuilder();

    const {
        page,
        topics,
        setCurrentTopics,
        items,
        setCurrentItems,
        lessonId,
        sidebarMode,
        setCurrentSidebarMode,
        loadPage,
        lessonName,
    } = lessonBuilderContext;

    const [previewUrl, setPreviewUrl] = useState("");

    const loadData = useCallback(() => {
        loadPage(+id, setLoading);
    }, [id, loadPage, setLoading]);

    useEffect(loadData, [id]);

    // update preview url
    useEffect(() => {
        if (page) {
            setPreviewUrl(`${webUrl}/lessons/pagebyid/${page.ID}`);
        } else {
            setPreviewUrl("");
        }
    }, [page, setPreviewUrl, webUrl]);

    const sortPageItems = () => {
        const model: LessonBuilderPageItemsSortModel = {
            Ids: items.map(i => i.ID),
            Types: items.map(i => i.TypeName),
        };

        LessonBuilderService.sortPageItems(model);
    };

    const sortTopics = () => {
        const model: LessonTopicPagesSortModel = {
            Ids: topics.map(t => t.ID),
        };

        LessonBuilderService.sortTopics(model);
    };

    return (
        <Page anchorTop={true}>
            <LessonBuilderContext.Provider value={lessonBuilderContext}>
                <PageFadeAnimation show={loading}>
                    <Loading />
                </PageFadeAnimation>

                <PageFadeAnimation show={!loading}>
                    <Container>
                        <Row>
                            <Col xl={12}>
                                {page && <LessonBuilderBreadcrumbs lessonId={lessonId} lessonName={lessonName} />}
                            </Col>
                            <Col xl={3} lg={4} md={5}>
                                <Card className="mb-3">
                                    <CardBody>
                                        <ButtonGroup className="w-100">
                                            <Button
                                                color={sidebarMode === "Sort" ? "primary" : "secondary"}
                                                onClick={() => setCurrentSidebarMode("Sort")}
                                            >
                                                Sort
                                            </Button>
                                            <Button
                                                color={sidebarMode === "Delete" ? "primary" : "secondary"}
                                                onClick={() => setCurrentSidebarMode("Delete")}
                                            >
                                                Delete
                                            </Button>
                                        </ButtonGroup>
                                    </CardBody>
                                </Card>

                                <ReactSortable
                                    list={topics}
                                    setList={setCurrentTopics}
                                    className="list-group"
                                    animation={300}
                                    onEnd={sortTopics}
                                    handle="[data-topic-sort]"
                                >
                                    {topics.map(t => (
                                        <LessonBuilderTopicComponent item={t} key={t.ID} />
                                    ))}
                                </ReactSortable>
                            </Col>
                            <Col xl={9} lg={8} md={7}>
                                <Card className="mb-3">
                                    <CardBody>
                                        {previewUrl && (
                                            <Button tag="a" href={previewUrl} target="_blank">
                                                Preview page
                                            </Button>
                                        )}{" "}
                                        <AddTopicModal />{" "}
                                        <Button
                                            tag="a"
                                            href={`${webUrl}/admin/lessons/edit/${lessonId}`}
                                            rel="noopener noreferrer"
                                        >
                                            Edit lesson
                                        </Button>
                                    </CardBody>
                                </Card>

                                <ReactSortable
                                    list={items}
                                    setList={setCurrentItems}
                                    handle="[data-drag-handle]"
                                    onEnd={sortPageItems}
                                    animation={300}
                                >
                                    {items.map(i => (
                                        <LessonBuilderItem item={i} key={`${i.Discriminator}${i.ID}`} />
                                    ))}
                                </ReactSortable>

                                <Card>
                                    <CardBody>
                                        <AddInstructionModal /> <AddSnippetModal /> <AddTextboxModal />{" "}
                                        <AddVideoModal /> <AddImageModal />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </PageFadeAnimation>
            </LessonBuilderContext.Provider>
        </Page>
    );
};
