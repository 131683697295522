import { BaseApiService } from "./BaseApiService";
import { WrotevoteCompareModel } from "../models/wrotevote/WrotevoteCompareModel";
import { WrotevoteRespondModel } from "../models/wrotevote/WrotevoteRespondModel";
import { WrotevoteReactModel } from "../models/wrotevote/WrotevoteReactModel";
import { WrotevoteVoteModel } from "../models/wrotevote/WrotevoteVoteModel";
import { WrotevoteLeaderboardModel } from "../models/wrotevote/WrotevoteLeaderboardModel";

export class WrotevoteService extends BaseApiService {
    static getCompare(groupId: number): Promise<WrotevoteCompareModel> {
        return this.get<WrotevoteCompareModel>(`/wrotevote/compare/${groupId}`);
    }

    static postRespond(model: WrotevoteRespondModel): Promise<any> {
        return this.post<any>("/wrotevote/respond", model);
    }

    static postVote(model: WrotevoteVoteModel): Promise<any> {
        return this.post<any>("/wrotevote/vote", model);
    }

    static postReaction(model: WrotevoteReactModel): Promise<any> {
        return this.post<any>("/wrotevote/react", model);
    }

    static getLeaderboard(groupId: number): Promise<WrotevoteLeaderboardModel> {
        return this.get<WrotevoteLeaderboardModel>(`/wrotevote/leaderboard/${groupId}`);
    }
}
