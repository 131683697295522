import { yupResolver } from "@hookform/resolvers";
import { FC, useContext, useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import {
    LessonTopicPageVideoEditModel,
    LessonTopicPageVideoEditModelValidationSchema,
} from "../../../models/crud/lessontopicpagevideos/LessonTopicPageVideoEditModel";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { Hidden } from "../../fields/Hidden";
import { FieldLessonTopicPageVideoID } from "../../fields/lesson-topic-page-videos/FieldLessonTopicPageVideoID";
import { Loading } from "../../Loading";

type Props = {
    id: number;
    onCompleted: () => void;
};

export const EditVideoModal: FC<Props> = (props: Props) => {
    const { id, onCompleted } = props;

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const nameId = nameof<LessonTopicPageVideoEditModel>("ID");

    const [modal, setModal] = useState(true);

    const { errors, register, handleSubmit, reset } = useForm<LessonTopicPageVideoEditModel>({
        resolver: yupResolver(LessonTopicPageVideoEditModelValidationSchema),
    });

    const toggleModal = () => {
        if (modal) {
            onCompleted();
        }

        setModal(!modal);
    };

    const loadData = useCallback(() => {
        LessonBuilderService.getVideo(+id)
            .then(x => {
                reset(x);
                setLoading(false);
            })
            .catch(confirmServerError);
    }, [confirmServerError, id, reset, setLoading]);

    useEffect(loadData, []);

    const onSubmit = handleSubmit(model => {
        setLoading(true);

        LessonBuilderService.updateVideo(model)
            .then(x => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
                onCompleted();
            })
            .catch(confirmServerError);
    });

    return (
        <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
            {loading ? (
                <Loading />
            ) : (
                <form onSubmit={onSubmit}>
                    <ModalHeader>Edit video</ModalHeader>
                    <ModalBody>
                        <Hidden name={nameId} value={id} register={register} />
                        <FieldLessonTopicPageVideoID register={register} errors={errors} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary">Update</Button>
                        <Button type="button" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </form>
            )}
        </Modal>
    );
};
