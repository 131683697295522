import { FC } from "react";
import { DeepMap, FieldError } from "@hookform/error-message/dist/types";
import { nameof } from "../../../utils/Helpers";
import { LessonTopicPageInstructionModel } from "../../../models/crud/lessontopicpageinstructions/LessonTopicPageInstructionModel";
import { Textbox } from "../Textbox";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLTextboxElement>(refOrValidationOptions: HTMLTextboxElement) => void | ((ref: HTMLTextboxElement | null) => void);
};

export const FieldLessonTopicPageInstructionCollapsedText: FC<Props> = (props: Props) => {
    const { errors, register } = props;

    const nameCollapsedText = nameof<LessonTopicPageInstructionModel>("CollapsedText");

    return <Textbox label="Collapsed text" name={nameCollapsedText} register={register} errors={errors} />;
};
