import { useState } from "react";
import { ErrorOption, FieldError } from "react-hook-form/dist/types/form";
import { DeepMap } from "react-hook-form/dist/types/utils";

export function useFormState<T> (defaultLoading = false) {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(defaultLoading);
    const [failure, setFailure] = useState(false);

    const confirmServerError = () => {
        setFailure(true);
        setLoading(false);
    };

    function handleFormResponse (
        success: boolean,
        errors: DeepMap<T, FieldError>,
        setError: (name: keyof T, error: ErrorOption) => void
    ) {
        if (success) {
            setSuccess(true);
        }

        for (let field in errors) {
            const errorList = errors[field] as Array<any>;

            for (let e of errorList) {
                setError(field, { type: "server", message: e });
            }
        }

        setLoading(false);
    };

    return {
        success,
        setSuccess,
        loading,
        setLoading,
        failure,
        setFailure,
        confirmServerError,
        handleFormResponse
    };
};
