import { FC, RefObject } from "react";
import { createMarkup } from "../utils/Helpers";
import clsx from "clsx";

type Props = {
    body: string;
    isExample?: boolean;
    innerRef?: RefObject<HTMLElement>;
};

export const Snippet: FC<Props> = (props: Props) => (
    <blockquote
        ref={props.innerRef}
        className={clsx("mb-3", "snippet", props.isExample ? "snippet-example" : "snippet-primary")}
        dangerouslySetInnerHTML={createMarkup(props.body)}
    ></blockquote>
);

Snippet.defaultProps = {
    isExample: false,
};
