import { FC, useRef, useEffect, useCallback } from "react";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { CustomErrors } from "../CustomErrors";
import { Label } from "reactstrap";
import { HelperText } from "../HelperText";
import { Constants } from "../../utils/Constants";
import { RouteList } from "../../Routes";

type Props = {
    name: string;
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLTextareaElement>(refOrValidationOptions: HTMLTextareaElement) => void | ((ref: HTMLTextareaElement | null) => void);
    label?: string;
    id?: string;
    value?: string | number | null | undefined;
    helperText?: string;
};

export const RichTextEditor: FC<Props> = (props: Props) => {
    const { name, errors, value, register, helperText } = props;

    const { webUrl } = Constants;

    const label = props.label ?? props.name;
    const id = props.id ?? props.name;

    const textarea = useRef<HTMLTextAreaElement | null>(null);

    const isValid = errors[name] == null;

    const loadData = useCallback(() => {
        if (textarea.current) {
            const ckeditor = CKEDITOR.replace(textarea.current, {
                toolbar: [
                    {
                        name: "main",
                        items: [
                            "Bold",
                            "Italic",
                            "Image",
                            "NumberedList",
                            "BulletedList",
                            "Link",
                            "Unlink",
                            "Styles",
                            "Source",
                        ],
                    },
                ],
                extraPlugins: "filebrowser",
                allowedContent: true,
                stylesSet: [
                    {
                        name: "Orange",
                        element: "span",
                        styles: {
                            "background-color": "rgba(255, 186, 20, 0.6)",
                        },
                    },
                    {
                        name: "Yellow",
                        element: "span",
                        styles: {
                            "background-color": "rgba(239, 242, 0, 0.6)",
                        },
                    },
                    {
                        name: "Green",
                        element: "span",
                        styles: {
                            "background-color": "rgba(168, 233, 75, 0.6)",
                        },
                    },
                    {
                        name: "Teal",
                        element: "span",
                        styles: {
                            "background-color": "rgba(71, 226, 189, 0.6)",
                        },
                    },
                    {
                        name: "Blue",
                        element: "span",
                        styles: {
                            "background-color": "rgba(97, 211, 255, 0.6)",
                        },
                    },
                    {
                        name: "Purple",
                        element: "span",
                        styles: {
                            "background-color": "rgba(153, 165, 255, 0.6)",
                        },
                    },
                    {
                        name: "Lavender",
                        element: "span",
                        styles: {
                            "background-color": "rgba(216, 165, 255, 0.6)",
                        },
                    },
                    {
                        name: "Pink",
                        element: "span",
                        styles: {
                            "background-color": "rgba(255, 126, 244, 0.6)",
                        },
                    },
                    {
                        name: "Red",
                        element: "span",
                        styles: {
                            "background-color": "rgba(255, 119, 132, 0.6)",
                        },
                    },
                    {
                        name: "Grey",
                        element: "span",
                        styles: {
                            "background-color": "rgba(188, 188, 188, 0.6)",
                        },
                    },
                ],
                filebrowserBrowseUrl: RouteList.ImagesPage,
                imageUploadUrl: `${webUrl}/admin/siteimages/create`,
                forcePasteAsPlainText: true,
                removeDialogTabs: "image:advanced;link:advanced",
            });

            // update the textarea element each time the editor changes
            ckeditor.on("key", e => {
                e.editor.updateElement();
            });

            // if we don't include this, then it doesn't update after pressing the delete key
            ckeditor.on("change", e => {
                e.editor.updateElement();
            });
        }
    }, [webUrl]);

    useEffect(loadData, []);

    return (
        <div className="mb-3 w-100">
            <Label htmlFor={id}>{label}</Label>
            <textarea
                id={id}
                name={name}
                defaultValue={value ?? ""}
                ref={e => {
                    // register so that validation works
                    register(e);

                    // set the ref so that we can initiate the editor
                    textarea.current = e;
                }}
            />
            {helperText && isValid && <HelperText text={helperText} />}
            <CustomErrors errors={errors} name={name} />
        </div>
    );
};
