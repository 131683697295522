import { FC, useState, useEffect } from "react";
import { Button, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {
    LessonBuilderSnippetSearchModel,
    LessonBuilderSnippetSearchResultItem,
    LessonBuilderSnippetSearchModelValidationSchema,
} from "../../../models/lessonbuilder/LessonBuilderSnippetSearchModel";
import { useForm } from "react-hook-form";
import { Textbox } from "../../fields/Textbox";
import { nameof } from "../../../utils/Helpers";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { useFormState } from "../../../hooks/useFormState";
import { Loading } from "../../Loading";
import { Snippet } from "../../Snippet";
import { yupResolver } from "@hookform/resolvers";
import { ComponentFadeAnimation } from "../../animation/ComponentFadeAnimation";
import { Constants } from "../../../utils/Constants";
import { SelectedSnippet } from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetEditModel";

type Props = {
    onSelectSnippet: (id: number) => void;
    selectedSnippet?: SelectedSnippet;
};

type SnippetFilter = "All" | "Snippets" | "Examples";

export const SnippetFinder: FC<Props> = (props: Props) => {
    const { onSelectSnippet, selectedSnippet } = props;
    const { webUrl } = Constants;

    const nameQuery = nameof<LessonBuilderSnippetSearchModel>("Query");

    const [allResults, setAllResults] = useState<Array<LessonBuilderSnippetSearchResultItem>>([]);
    const [filteredResults, setFilteredResults] = useState<Array<LessonBuilderSnippetSearchResultItem>>([]);
    const [currentFilter, setCurrentFilter] = useState<SnippetFilter>("All");

    const { register, handleSubmit, errors } = useForm<LessonBuilderSnippetSearchModel>({
        resolver: yupResolver(LessonBuilderSnippetSearchModelValidationSchema),
    });

    const { loading, confirmServerError, setLoading } = useFormState();

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.searchSnippets(model)
            .then((x) => {
                setAllResults(x.Data.Items);
                setLoading(false);
            })
            .catch(confirmServerError);
    });

    const removeResult = (result: LessonBuilderSnippetSearchResultItem) => {
        var newResults = allResults.filter((r) => r !== result);

        setAllResults(newResults);
    };

    useEffect(() => {
        switch (currentFilter) {
            case "All":
                setFilteredResults(allResults);
                break;
            case "Examples":
                setFilteredResults(allResults.filter((x) => x.IsExample));
                break;
            case "Snippets":
                setFilteredResults(allResults.filter((x) => !x.IsExample));
                break;
        }
    }, [allResults, currentFilter]);

    return (
        <form onSubmit={onSubmit}>
            <Row>
                <Col sm={4}>
                    <Textbox hideLabel={true} register={register} errors={errors} name={nameQuery} />
                    <Button color="primary">Search</Button>{" "}
                    <Button tag="a" target="_blank" href={`${webUrl}/admin/snippets/create`}>
                        Add new snippet
                    </Button>
                    {selectedSnippet && (
                        <div className="mt-5">
                            <h2 className="h5">Current snippet</h2>
                            <Snippet body={selectedSnippet.Body} isExample={selectedSnippet.IsExample} />
                        </div>
                    )}
                </Col>

                <Col sm={8}>
                    <fieldset className="mb-4">
                        <legend className="sr-only">Show:</legend>
                        <FormGroup check inline>
                            <Label check htmlFor="rAll">
                                <Input type="radio" id="rAll" name="show" checked={currentFilter === "All"} onChange={() => setCurrentFilter("All")} /> All
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check htmlFor="rSnippets">
                                <Input
                                    type="radio"
                                    id="rSnippets"
                                    name="show"
                                    checked={currentFilter === "Snippets"}
                                    onChange={() => setCurrentFilter("Snippets")}
                                />{" "}
                                Snippets
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check htmlFor="rExamples">
                                <Input
                                    type="radio"
                                    id="rExamples"
                                    name="show"
                                    checked={currentFilter === "Examples"}
                                    onChange={() => setCurrentFilter("Examples")}
                                />{" "}
                                Examples
                            </Label>
                        </FormGroup>
                    </fieldset>

                    <div className="mb-3">
                        Results: <strong>{filteredResults.length}</strong>
                    </div>

                    <div>
                        <ComponentFadeAnimation show={loading}>
                            <Loading />
                        </ComponentFadeAnimation>

                        <ComponentFadeAnimation show={!loading}>
                            {filteredResults.map((r) => {
                                return (
                                    <div key={r.ID} className="mb-4">
                                        <Snippet body={r.Body} isExample={r.IsExample} />
                                        <Button type="button" onClick={() => onSelectSnippet(r.ID)}>
                                            Use snippet
                                        </Button>{" "}
                                        <Button type="button" color="danger" onClick={() => removeResult(r)}>
                                            Remove from search
                                        </Button>
                                    </div>
                                );
                            })}
                        </ComponentFadeAnimation>
                    </div>
                </Col>
            </Row>
        </form>
    );
};
