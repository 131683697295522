import * as yup from "yup";
import { SelectListItem } from "../../SelectListItem";
import { LessonTopicPageModel, LessonTopicPageModelValidationRules } from "./LessonTopicPageModel";

export interface LessonTopicPageEditModel extends LessonTopicPageModel {
    ID: number;
    LessonTopicIDSelectList?: Array<SelectListItem>;
}

export const LessonTopicPageEditModelValidationSchema = yup.object().shape<LessonTopicPageEditModel>({
    ID: yup.number().required(),
    Name: LessonTopicPageModelValidationRules.Name,
    IsAssessable: LessonTopicPageModelValidationRules.IsAssessable,
    LessonTopicID: LessonTopicPageModelValidationRules.LessonTopicID,
});
