import { FC } from "react";
import { LessonBuilderPageTextboxModel } from "../../../models/lessonbuilder/pageitems/LessonBuilderPageTextboxModel";
import { CardBody } from "reactstrap";

type Props = {
    model: LessonBuilderPageTextboxModel;
};

export const LessonBuilderPageTextbox: FC<Props> = (props: Props) => {
    const { model } = props;

    return (
        <CardBody>
            <div className="mb-3">{model.Label}</div>
            <div>
                <textarea disabled className="form-control" />
            </div>
        </CardBody>
    );
};
