import { FC, useState, useContext, useEffect, Fragment } from "react";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import { LessonTopicPageEditModel, LessonTopicPageEditModelValidationSchema } from "../../../models/crud/lessontopicpages/LessonTopicPageEditModel";
import { nameof } from "../../../utils/Helpers";
import { useFormState } from "../../../hooks/useFormState";
import { Textbox } from "../../fields/Textbox";
import { Hidden } from "../../fields/Hidden";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { yupResolver } from "@hookform/resolvers";
import { Loading } from "../../Loading";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { Checkbox } from "../../fields/Checkbox";
import { SelectList } from "../../fields/SelectList";
import { SelectListItem } from "../../../models/SelectListItem";

export const EditPageModal: FC = () => {
    const [modal, setModal] = useState(false);
    const [lessonTopicIDSelectListOptions, setLessonTopicIDSelectListOptions] = useState<Array<SelectListItem>>([]);

    const nameId = nameof<LessonTopicPageEditModel>("ID");
    const nameName = nameof<LessonTopicPageEditModel>("Name");
    const nameIsAssessable = nameof<LessonTopicPageEditModel>("IsAssessable");
    const nameLessonTopicId = nameof<LessonTopicPageEditModel>("LessonTopicID");

    const { page, topics, loadPage } = useContext(LessonBuilderContext);

    const toggleModal = () => setModal(!modal);

    const { setLoading, confirmServerError, loading, handleFormResponse } = useFormState();

    const { errors, register, handleSubmit, setError, reset } = useForm<LessonTopicPageEditModel>({
        resolver: yupResolver(LessonTopicPageEditModelValidationSchema),
    });

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.editPage(model)
            .then((x) => {
                if (x.Success) {
                    loadPage(model.ID, setLoading);
                    setModal(false);
                }

                handleFormResponse(x.Success, x.Errors, setError);
            })
            .catch(confirmServerError);
    });

    useEffect(() => {
        if (page) {
            // build topics select list
            const topicOptions: Array<SelectListItem> = topics.map((t) => {
                return { Text: t.Name, Value: t.ID };
            });

            setLessonTopicIDSelectListOptions(topicOptions);
            reset(page);
        }
    }, [page, reset, topics]);

    return (
        <Fragment>
            {page && (
                <button type="button" className="btn btn-link p-0" onClick={toggleModal}>
                    {page.Name}
                </button>
            )}
            <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
                {loading ? (
                    <Loading />
                ) : (
                    page && (
                        <form onSubmit={onSubmit}>
                            <ModalHeader>Update page</ModalHeader>
                            <ModalBody>
                                <Textbox name={nameName} errors={errors} register={register} label="Name" value={page.Name} />
                                <SelectList
                                    label="Topic"
                                    name={nameLessonTopicId}
                                    register={register}
                                    errors={errors}
                                    value={page.LessonTopicID}
                                    options={lessonTopicIDSelectListOptions}
                                />
                                <Checkbox
                                    name={nameIsAssessable}
                                    errors={errors}
                                    register={register}
                                    label="Textboxes on this page can be graded in checkpoint review"
                                />
                                <Hidden name={nameId} register={register} value={page.ID} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary">Update</Button>
                                <Button color="secondary" type="button" onClick={() => setModal(false)}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </form>
                    )
                )}
            </Modal>
        </Fragment>
    );
};
