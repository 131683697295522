import { yupResolver } from "@hookform/resolvers";
import { FC, useContext, useState } from "react";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { LessonTopicCreateModel, LessonTopicCreateModelValidationSchema } from "../../../models/crud/lessontopics/LessonTopicCreateModel";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { Hidden } from "../../fields/Hidden";
import { Textbox } from "../../fields/Textbox";
import { Loading } from "../../Loading";

export const AddTopicModal: FC = () => {
    const nameLessonId = nameof<LessonTopicCreateModel>("LessonID");
    const nameName = nameof<LessonTopicCreateModel>("Name");

    const { addTopic, lessonId } = useContext(LessonBuilderContext);

    const { register, handleSubmit, errors } = useForm<LessonTopicCreateModel>({
        resolver: yupResolver(LessonTopicCreateModelValidationSchema),
    });

    const { setLoading, confirmServerError, loading } = useFormState();

    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.addTopic(model)
            .then((x) => {
                addTopic(x.Data);
                setModal(false);
                setLoading(false);
            })
            .catch(confirmServerError);
    });

    return (
        <Fragment>
            <Button onClick={toggleModal}>
                Add topic
            </Button>

            <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
                {loading ? (
                    <Loading />
                ) : (
                    <form onSubmit={onSubmit}>
                        <ModalHeader>Add topic</ModalHeader>
                        <ModalBody>
                            <Hidden name={nameLessonId} value={lessonId} register={register} />
                            <Textbox register={register} name={nameName} errors={errors} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary">Add</Button>
                            <Button type="button" onClick={() => setModal(false)}>
                                Close
                            </Button>
                        </ModalFooter>
                    </form>
                )}
            </Modal>
        </Fragment>
    );
};
