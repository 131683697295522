import * as yup from "yup";

export interface LessonTopicPageImageModel {
    Caption?: string;
    SourceUrl?: string;
    ImageID: number;
}

const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

export const LessonTopicPageImageModelValidationRules = {
    Caption: yup.string().optional().max(255),
    ImageID: yup.number().required().typeError("An image is required").min(1, "Please select an image"),
    SourceUrl: yup.string().optional().max(255).matches(expression),
};
