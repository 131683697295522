import { FC } from "react";
import { Spinner } from "reactstrap";

export const LoadingIcon: FC = () => <Spinner />;

export const Loading: FC = () => {
    return (
        <div className="d-flex justify-content-center align-items-center py-5">
            <LoadingIcon />
        </div>
    );
};
