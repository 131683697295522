import { FC } from "react";
import { ComponentFadeAnimation } from "../../animation/ComponentFadeAnimation";
import { Loading } from "../../Loading";
import { SnippetFinder } from "../../lessonbuilder/snippets/SnippetFinder";
import { SelectedSnippet } from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetEditModel";

type Props = {
    loading: boolean;
    currentSnippet?: SelectedSnippet | undefined;
    selectSnippet: (id: number) => void;
};

export const FieldLessonTopicPageSnippetSnippet: FC<Props> = (props: Props) => {
    const { loading, currentSnippet, selectSnippet } = props;

    return (
        <div>
            <ComponentFadeAnimation show={loading}>
                <Loading />
            </ComponentFadeAnimation>

            <ComponentFadeAnimation show={!loading}>
                <SnippetFinder onSelectSnippet={selectSnippet} selectedSnippet={currentSnippet} />
            </ComponentFadeAnimation>
        </div>
    );
};
