import { FC, useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { RouteList } from "../../Routes";
import { ReactSortable } from "react-sortablejs";
import { ListGroupItem, Button } from "reactstrap";
import { LessonTopicsSortModel } from "../../models/crud/lessontopics/LessonTopicsSortModel";
import { LessonBuilderTopic, LessonBuilderTopicPage } from "../../models/lessonbuilder/LessonBuilderPageModel";
import { ArrowsMove, Trash } from "react-bootstrap-icons";
import { LessonBuilderService } from "../../services/LessonBuilderService";
import { LessonBuilderContext } from "../../hooks/useLessonBuilder";
import { AddPageModal } from "./pages/AddPageModal";

type Props = {
    item: LessonBuilderTopic;
};

export const LessonBuilderTopicComponent: FC<Props> = (props: Props) => {
    const { item } = props;

    const { page, topics, setCurrentTopics, sidebarMode, removePage, removeTopic } = useContext(LessonBuilderContext);

    const [pages, setPages] = useState<Array<LessonBuilderTopicPage>>([]);

    const sortPages = () => {
        const model: LessonTopicsSortModel = {
            Ids: pages.map(p => p.ID),
        };

        LessonBuilderService.sortPages(model);

        // update global state
        setCurrentTopics(topics.map(t => (t.ID === item.ID ? { ...t, Pages: pages } : t)));
    };

    const deletePage = (id: number) => {
        if (window.confirm("Are you sure you want to delete this page?")) {
            LessonBuilderService.deletePage(id).then(() => {
                removePage(id);
            });
        }
    };

    const deleteTopic = (id: number) => {
        if (window.confirm("Are you sure you want to delete this topic?")) {
            LessonBuilderService.deleteTopic(id).then(() => {
                removeTopic(id);
            });
        }
    };

    useEffect(() => {
        setPages(item.Pages);
    }, [item]);

    return (
        <ListGroupItem key={item.ID} className={clsx("mb-3", "slide", item.AnimationAdding && "slide-up")}>
            <div className="mb-3 d-flex align-items-center justify-content-between">
                <div className="mr-2">{item.Name}</div>
                <div>
                    {sidebarMode === "Sort" && (
                        <Button
                            type="button"
                            color="link"
                            className="p-0 cursor-move"
                            size="sm"
                            title="Drag to sort"
                            data-topic-sort
                        >
                            <ArrowsMove />
                        </Button>
                    )}
                    {sidebarMode === "Delete" && (
                        <Button
                            color="link"
                            className="p-0"
                            size="sm"
                            title="Delete topic"
                            onClick={() => deleteTopic(item.ID)}
                        >
                            <Trash />
                        </Button>
                    )}
                </div>
            </div>

            {page && (
                <ReactSortable
                    className="mb-3"
                    list={pages}
                    setList={setPages}
                    handle="[data-page-sort]"
                    animation={300}
                    onEnd={sortPages}
                >
                    {pages.map(p => (
                        <ListGroupItem key={p.ID} className={clsx(page.ID === p.ID && "bg-primary")} tag="div">
                            <div className={clsx("d-flex", "align-items-center", "justify-content-between")}>
                                <div className="mr-2">
                                    <Link
                                        to={RouteList.LessonBuilderPage(p.ID.toString())}
                                        className={clsx(
                                            "btn",
                                            "btn-link",
                                            "p-0",
                                            "text-left",
                                            page.ID === p.ID && "text-white",
                                        )}
                                    >
                                        {p.Name}
                                    </Link>
                                </div>
                                <div>
                                    {sidebarMode === "Sort" && (
                                        <Button
                                            title="Drag to sort"
                                            type="button"
                                            color="link"
                                            className="p-0 cursor-move"
                                            size="sm"
                                            data-page-sort
                                        >
                                            <ArrowsMove />
                                        </Button>
                                    )}
                                    {sidebarMode === "Delete" && page.ID !== p.ID && (
                                        <Button
                                            title="Delete page"
                                            onClick={() => deletePage(p.ID)}
                                            color="link"
                                            className="p-0"
                                            size="sm"
                                        >
                                            <Trash />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </ListGroupItem>
                    ))}
                </ReactSortable>
            )}

            <AddPageModal topicId={item.ID} />
        </ListGroupItem>
    );
};
