import { FC, useContext, useState, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { useFormState } from "../../../hooks/useFormState";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import {
    LessonTopicPageSnippetCreateModel,
    LessonTopicPageSnippetCreateModelValidationSchema,
} from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetCreateModel";
import { nameof } from "../../../utils/Helpers";
import { Hidden } from "../../fields/Hidden";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { FieldLessonTopicPageSnippetSnippet } from "../../fields/lesson-topic-page-snippets/FieldLessonTopicPageSnippetSnippet";

export const AddSnippetModal: FC = () => {
    const nameLessonTopicPageId = nameof<LessonTopicPageSnippetCreateModel>("LessonTopicPageID");
    const nameSnippetId = nameof<LessonTopicPageSnippetCreateModel>("SnippetID");

    const [modal, setModal] = useState(false);

    const { page, addPageItem } = useContext(LessonBuilderContext);
    const { setLoading, loading, confirmServerError } = useFormState();

    const { register, handleSubmit, setValue } = useForm<LessonTopicPageSnippetCreateModel>({
        resolver: yupResolver(LessonTopicPageSnippetCreateModelValidationSchema),
    });

    const toggleModal = () => setModal(!modal);

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.addSnippet(model)
            .then((x) => {
                addPageItem(x.Data);
                setModal(false);
                setLoading(false);
            })
            .catch(confirmServerError);
    });

    const selectSnippet = (id: number) => {
        setValue(nameSnippetId, id);
        onSubmit();
    };

    return (
        <Fragment>
            <Button type="button" onClick={toggleModal}>
                Add snippet
            </Button>

            <Modal contentClassName="modal-content--tall" external={false} isOpen={modal} toggle={toggleModal} centered={true} size="xl" scrollable={true}>
                <ModalHeader>Add snippet</ModalHeader>
                <ModalBody>
                    <FieldLessonTopicPageSnippetSnippet loading={loading} selectSnippet={selectSnippet} />
                </ModalBody>
                <ModalFooter>
                    {page && (
                        <form onSubmit={onSubmit}>
                            <Hidden name={nameSnippetId} register={register} />
                            <Hidden name={nameLessonTopicPageId} value={page.ID} register={register} />
                        </form>
                    )}
                    <Button type="button" onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};
