import { yupResolver } from "@hookform/resolvers";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import {
    LessonTopicPageImageEditModel,
    LessonTopicPageImageEditModelValidationSchema,
} from "../../../models/crud/lessontopicpageimages/LessonTopicPageImageEditModel";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Loading } from "../../Loading";
import { Hidden } from "../../fields/Hidden";
import { FieldLessonTopicPageImageCaption } from "../../fields/lesson-topic-page-images/FieldLessonTopicPageImageCaption";
import { FieldLessonTopicPageImageImageID } from "../../fields/lesson-topic-page-images/FieldLessonTopicPageImageImageID";
import { ImageListItem } from "../../../models/ImageListItem";
import { FieldLessonTopicPageImageSourceUrl } from "../../fields/lesson-topic-page-images/FieldLessonTopicPageImageSourceUrl";

const nameId = nameof<LessonTopicPageImageEditModel>("ID");
const nameImageUrl = nameof<LessonTopicPageImageEditModel>("ImageUrl");

export const EditImageModal = (props: { id: number; onCompleted: () => void }) => {
    const { id, onCompleted } = props;

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const [modal, setModal] = useState(true);

    const { errors, register, handleSubmit, reset, setValue, control } = useForm<LessonTopicPageImageEditModel>({
        resolver: yupResolver(LessonTopicPageImageEditModelValidationSchema),
    });

    const { ImageUrl } = useWatch<LessonTopicPageImageEditModel>({ control });

    const toggleModal = () => {
        if (modal) {
            onCompleted();
        }

        setModal(!modal);
    };

    const [initialImage, setInitialImage] = useState<ImageListItem>();

    const loadData = useCallback(() => {
        LessonBuilderService.getImage(+id)
            .then(x => {
                reset(x);
                setLoading(false);

                if (x.ImageUrl) {
                    setInitialImage({ ImageUrl: x.ImageUrl, ID: x.ImageID, Name: "Initial image" });
                }
            })
            .catch(confirmServerError);
    }, [confirmServerError, id, reset, setLoading]);

    useEffect(loadData, []);

    const onSubmit = handleSubmit(model => {
        setLoading(true);

        LessonBuilderService.updateImage(model)
            .then(x => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
                onCompleted();
            })
            .catch(confirmServerError);
    });

    return (
        <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
            {loading ? (
                <Loading />
            ) : (
                <form onSubmit={onSubmit}>
                    <ModalHeader>Edit image</ModalHeader>
                    <ModalBody>
                        <Hidden name={nameId} value={id} register={register} />
                        <Hidden name={nameImageUrl} value={ImageUrl} register={register} />
                        <FieldLessonTopicPageImageCaption register={register} errors={errors} />
                        <FieldLessonTopicPageImageSourceUrl register={register} errors={errors} />
                        <FieldLessonTopicPageImageImageID
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            initialSelectedImage={initialImage}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary">Update</Button>
                        <Button type="button" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </form>
            )}
        </Modal>
    );
};
