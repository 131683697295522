import { FC, Fragment, useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { Container, Row, Col, Modal } from "reactstrap";
import {
    WrotevoteCompareModel,
    WrotevoteCompareReply,
    WrotevoteComparePair,
} from "../../models/wrotevote/WrotevoteCompareModel";
import {
    WrotevoteRespondModel,
    WrotevoteRespondModelValidationSchema,
} from "../../models/wrotevote/WrotevoteRespondModel";
import { WrotevoteVoteModel } from "../../models/wrotevote/WrotevoteVoteModel";
import { WrotevoteLogoHeader } from "../../components/wrotevote/WrotevoteLogoHeader";
import { nameof } from "../../utils/Helpers";
import { useFormState } from "../../hooks/useFormState";
import { WrotevoteService } from "../../services/WrotevoteService";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { Textbox } from "../../components/fields/Textbox";
import { WrotevoteReactModel } from "../../models/wrotevote/WrotevoteReactModel";
import { useHistory } from "react-router-dom";
import { RouteList } from "../../Routes";
import { Page } from "../Page";
import { Loading } from "../../components/Loading";
import { PageFadeAnimation } from "../../components/animation/PageFadeAnimation";
import { HalfPageColumn } from "../../components/HalfPageColumn";
import { Reply } from "../../components/Reply";
import { useCharacters } from "../../hooks/useCharacters";

type Props = {
    id: string;
};

export const WrotevoteComparePage: FC<Props> = (props: Props) => {
    const { id } = props;

    const nameComment = nameof<WrotevoteRespondModel>("Comment");
    const nameReplyID = nameof<WrotevoteRespondModel>("ReplyID");

    const history = useHistory();

    const { setLoading, confirmServerError, loading } = useFormState(true);

    const { register, handleSubmit, errors, setValue } = useForm<WrotevoteRespondModel>({
        resolver: yupResolver(WrotevoteRespondModelValidationSchema),
    });

    const { getRandomCharacterPair } = useCharacters();

    const [wrotevote, setWrotevote] = useState<WrotevoteCompareModel>();
    const [modal, setModal] = useState(false);
    const [selectedReply, setSelectedReply] = useState<WrotevoteCompareReply>();
    const [selectedReactions, setSelectedReactions] = useState<Array<number>>([]);
    const [currentRound, setCurrentRound] = useState<WrotevoteComparePair>();

    const toggleModal = () => setModal(!modal);

    const postVote = (winnerReplyId: number, loserReplyId: number) => {
        const model: WrotevoteVoteModel = {
            WinnerReplyID: winnerReplyId,
            LoserReplyID: loserReplyId,
        };

        WrotevoteService.postVote(model).catch(confirmServerError);
    };

    const selectReply = (winningReply: WrotevoteCompareReply, losingReply: WrotevoteCompareReply) => {
        postVote(winningReply.ReplyID, losingReply.ReplyID);
        setSelectedReply(winningReply);
        setValue(nameReplyID, winningReply.ReplyID);
        window.scroll(0, 0);
    };

    const selectReaction = (reactionId: number, replyId: number) => {
        setSelectedReactions([...selectedReactions, reactionId]);

        const model: WrotevoteReactModel = {
            ReactionID: reactionId,
            ReplyID: replyId,
        };

        WrotevoteService.postReaction(model).catch(confirmServerError);
    };

    const onSubmit = handleSubmit(model => {
        if (model.Comment) {
            WrotevoteService.postRespond(model);
        }

        // go to next round if it exists
        if (wrotevote && currentRound && wrotevote.Pairs[currentRound.RoundNumber]) {
            setCurrentRound(wrotevote.Pairs[currentRound.RoundNumber]);
            setSelectedReply(undefined);
            setSelectedReactions([]);
        } else {
            history.push(RouteList.WrotevoteLeaderboard(id));
        }
    });

    const loadData = useCallback(() => {
        WrotevoteService.getCompare(+id)
            .then(compareModel => {
                // if there's no pair to compare, then just go to the leaderboard
                if (compareModel.Pairs.length === 0) {
                    history.push(RouteList.WrotevoteLeaderboard(id));
                }

                // populate each pair with characters
                for (const pair of compareModel.Pairs) {
                    const characters = getRandomCharacterPair();
                    pair.Reply1.Character = characters[0];
                    pair.Reply2.Character = characters[1];
                }

                setWrotevote(compareModel);
                setCurrentRound(compareModel.Pairs[0]);
                setLoading(false);
            })
            .catch(confirmServerError);
    }, [confirmServerError, setLoading, id, history, getRandomCharacterPair]);

    useEffect(loadData, []);

    return (
        <Page className="page-wrotevote">
            <PageFadeAnimation show={loading}>
                <Loading />
            </PageFadeAnimation>

            <PageFadeAnimation show={selectedReply == null && !loading}>
                <Container>
                    <WrotevoteLogoHeader />

                    {currentRound && wrotevote && currentRound.Reply1 && currentRound.Reply2 && (
                        <Row>
                            <HalfPageColumn>
                                <div className="text-center">
                                    <p>
                                        <strong>
                                            Round {currentRound.RoundNumber} of {wrotevote.Pairs.length}
                                        </strong>
                                    </p>

                                    <p className="lead mb-4">{currentRound.Prompt}</p>
                                </div>

                                {currentRound.ImageUrl && (
                                    <Fragment>
                                        <div className="mb-5 text-center">
                                            <button onClick={toggleModal}>Show image</button>
                                        </div>
                                        <Modal centered={true} isOpen={modal} toggle={toggleModal}>
                                            <img alt="" src={currentRound.ImageUrl} className="img-fluid" />
                                        </Modal>
                                    </Fragment>
                                )}
                            </HalfPageColumn>
                            <Col sm="6" className="mb-4">
                                <div className="d-flex flex-column h-100">
                                    <div className="d-flex align-items-end">
                                        <strong className="mb-2 mr-3">Person 1 wrote</strong>{" "}
                                        <img alt="" className="peek" src={currentRound.Reply1.Character} />
                                    </div>

                                    <Reply body={currentRound.Reply1.Body} />

                                    <div className="text-center">
                                        <button
                                            type="button"
                                            onClick={() => selectReply(currentRound.Reply1, currentRound.Reply2)}
                                        >
                                            Vote for person 1
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" className="mb-4">
                                <div className="d-flex flex-column h-100">
                                    <div className="d-flex align-items-end">
                                        <strong className="mb-2 mr-3">Person 2 wrote</strong>{" "}
                                        <img alt="" className="peek" src={currentRound.Reply2.Character} />
                                    </div>

                                    <Reply body={currentRound.Reply2.Body} />

                                    <div className="text-center">
                                        <button
                                            type="button"
                                            onClick={() => selectReply(currentRound.Reply2, currentRound.Reply1)}
                                        >
                                            Vote for person 2
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </PageFadeAnimation>

            <PageFadeAnimation show={selectedReply != null && !loading}>
                <Container>
                    {selectedReply && (
                        <div className="text-center">
                            <WrotevoteLogoHeader />

                            <Row>
                                <Col sm="6" className="text-left">
                                    <p className="lead  mb-5">
                                        Your choice was written by:
                                        <br />
                                        <strong className="h1 font-weight-bold">{selectedReply.Name}</strong>
                                    </p>

                                    {wrotevote && currentRound && (
                                        <form onSubmit={onSubmit}>
                                            <div className="mb-3">
                                                <small>
                                                    Comment and react below, or click
                                                    <br />
                                                    <strong>Next Wrotevote</strong> to continue
                                                </small>
                                            </div>

                                            <div className="mb-4 d-inline-flex flex-column">
                                                <div className="mb-2">
                                                    {wrotevote.Reactions.map(r => {
                                                        const selected = selectedReactions.indexOf(r.ID) > -1;

                                                        return (
                                                            <Fragment key={r.ID}>
                                                                <button
                                                                    onClick={() =>
                                                                        selectReaction(r.ID, selectedReply.ReplyID)
                                                                    }
                                                                    type="button"
                                                                    title={r.Name}
                                                                    disabled={selected}
                                                                    className={clsx("btn-reaction")}
                                                                >
                                                                    <img alt={r.Name} src={r.ImageUrl} />
                                                                </button>{" "}
                                                            </Fragment>
                                                        );
                                                    })}
                                                </div>

                                                <div>
                                                    <Textbox
                                                        type="textarea"
                                                        register={register}
                                                        errors={errors}
                                                        name={nameComment}
                                                        hideLabel={true}
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <button>
                                                    {wrotevote.Pairs[currentRound.RoundNumber]
                                                        ? "Next Wrotevote"
                                                        : "Finish round"}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Col>
                                <Col sm="6">
                                    <div className="text-right">
                                        <img alt="" src={selectedReply.Character} className="peek" />
                                    </div>

                                    <Reply body={selectedReply.Body} />
                                </Col>
                            </Row>
                        </div>
                    )}
                </Container>
            </PageFadeAnimation>

            <input ref={register} name={nameReplyID} type="hidden" />
        </Page>
    );
};
