import { BaseApiService } from "./BaseApiService";
import { IApiPostResult } from "../models/api/IApiPostResult";
import { LessonTopicEditModel } from "../models/crud/lessontopics/LessonTopicEditModel";
import {
    LessonBuilderPageModel,
    LessonBuilderTopic,
    LessonBuilderTopicPage,
} from "../models/lessonbuilder/LessonBuilderPageModel";
import { LessonTopicPageEditModel } from "../models/crud/lessontopicpages/LessonTopicPageEditModel";
import { LessonTopicPagesSortModel } from "../models/crud/lessontopicpages/LessonTopicPagesSortModel";
import { LessonBuilderPageItemsSortModel } from "../models/lessonbuilder/pageitems/LessonBuilderPageItemsSortModel";
import { LessonBuilderPageItemDeleteModel } from "../models/lessonbuilder/pageitems/LessonBuilderPageItemDeleteModel";
import { LessonBuilderPageInstructionModel } from "../models/lessonbuilder/pageitems/LessonBuilderPageInstructionModel";
import { LessonTopicPageInstructionEditModel } from "../models/crud/lessontopicpageinstructions/LessonTopicPageInstructionEditModel";
import { LessonTopicPageInstructionCreateModel } from "../models/crud/lessontopicpageinstructions/LessonTopicPageInstructionCreateModel";
import { LessonBuilderPageTextboxModel } from "../models/lessonbuilder/pageitems/LessonBuilderPageTextboxModel";
import { LessonTopicPageTextboxEditModel } from "../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxEditModel";
import { LessonTopicPageTextboxCreateModel } from "../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxCreateModel";
import {
    LessonBuilderSnippetSearchModel,
    LessonBuilderSnippetSearchResult,
} from "../models/lessonbuilder/LessonBuilderSnippetSearchModel";
import { LessonTopicPageSnippetCreateModel } from "../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetCreateModel";
import { LessonTopicPageSnippetEditModel } from "../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetEditModel";
import { LessonBuilderPageSnippetModel } from "../models/lessonbuilder/pageitems/LessonBuilderPageSnippetModel";
import { LessonTopicPageSnippetHighlightingModel } from "../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetHighlightingModel";
import { LessonTopicPageSnippetExpansionModel } from "../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetExpansionModel";
import { LessonTopicPageSnippetMultiHighlightingModel } from "../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetMultiHighlightingModel";
import { LessonTopicCreateModel } from "../models/crud/lessontopics/LessonTopicCreateModel";
import { LessonTopicPageModel } from "../models/crud/lessontopicpages/LessonTopicPageModel";
import { LessonTopicPageVideoEditModel } from "../models/crud/lessontopicpagevideos/LessonTopicPageVideoEditModel";
import { LessonTopicPageVideoCreateModel } from "../models/crud/lessontopicpagevideos/LessonTopicPageVideoCreateModel";
import { LessonBuilderPageVideoModel } from "../models/lessonbuilder/pageitems/LessonBuilderPageVideoModel";
import { ImageListItem } from "../models/ImageListItem";
import { LessonTopicPageImageCreateModel } from "../models/crud/lessontopicpageimages/LessonTopicPageImageCreateModel";
import { LessonTopicPageImageEditModel } from "../models/crud/lessontopicpageimages/LessonTopicPageImageEditModel";
import { LessonBuilderPageImageModel } from "../models/lessonbuilder/pageitems/LessonBuilderPageImageModel";

export class LessonBuilderService extends BaseApiService {
    static getPage(id: number): Promise<LessonBuilderPageModel> {
        return this.get<LessonBuilderPageModel>(`/lessonbuilder/page/${id}`);
    }

    static createPage(model: LessonTopicPageModel): Promise<IApiPostResult<LessonBuilderTopicPage>> {
        return this.post<LessonBuilderTopicPage>("/lessonbuilder/page/create", model);
    }

    static editPage(model: LessonTopicPageEditModel): Promise<IApiPostResult<any>> {
        return this.put<any>("/lessonbuilder/page/edit", model);
    }

    static deletePage(id: number): Promise<IApiPostResult<any>> {
        return this.delete<any>(`/lessonbuilder/page/delete/${id}`, {});
    }

    static addTopic(model: LessonTopicCreateModel): Promise<IApiPostResult<LessonBuilderTopic>> {
        return this.post<LessonBuilderTopic>("/lessonbuilder/topic/create", model);
    }

    static editTopic(model: LessonTopicEditModel): Promise<IApiPostResult<any>> {
        return this.put<any>("/lessonbuilder/topic/edit", model);
    }

    static deleteTopic(id: number): Promise<IApiPostResult<any>> {
        return this.delete<any>(`/lessonbuilder/topic/delete/${id}`, {});
    }

    static sortTopics(model: LessonTopicPagesSortModel): Promise<IApiPostResult<any>> {
        return this.put<any>("/lessonbuilder/topics/sort", model);
    }

    static sortPages(model: LessonTopicPagesSortModel): Promise<IApiPostResult<any>> {
        return this.put<any>("/lessonbuilder/pages/sort", model);
    }

    static sortPageItems(model: LessonBuilderPageItemsSortModel): Promise<IApiPostResult<any>> {
        return this.put<any>("/lessonbuilder/pageitems/sort", model);
    }

    static deletePageItem(model: LessonBuilderPageItemDeleteModel): Promise<IApiPostResult<any>> {
        return this.delete<any>("/lessonbuilder/pageitem/delete", model);
    }

    static getInstruction(id: number): Promise<LessonTopicPageInstructionEditModel> {
        return this.get<LessonTopicPageInstructionEditModel>(`/lessonbuilder/instruction/get/${id}`);
    }

    static addInstruction(
        model: LessonTopicPageInstructionCreateModel,
    ): Promise<IApiPostResult<LessonBuilderPageInstructionModel>> {
        return this.post<LessonBuilderPageInstructionModel>("/lessonbuilder/instruction/create", model);
    }

    static updateInstruction(
        model: LessonTopicPageInstructionEditModel,
    ): Promise<IApiPostResult<LessonBuilderPageInstructionModel>> {
        return this.put<LessonBuilderPageInstructionModel>("/lessonbuilder/instruction/edit", model);
    }

    static getVideo(id: number): Promise<LessonTopicPageVideoEditModel> {
        return this.get<LessonTopicPageVideoEditModel>(`/lessonbuilder/video/get/${id}`);
    }

    static addVideo(model: LessonTopicPageVideoCreateModel): Promise<IApiPostResult<LessonBuilderPageVideoModel>> {
        return this.post<LessonBuilderPageVideoModel>("/lessonbuilder/video/create", model);
    }

    static updateVideo(model: LessonTopicPageVideoEditModel): Promise<IApiPostResult<LessonBuilderPageVideoModel>> {
        return this.put<LessonBuilderPageVideoModel>("/lessonbuilder/video/edit", model);
    }

    static getImage(id: number): Promise<LessonTopicPageImageEditModel> {
        return this.get<LessonTopicPageImageEditModel>(`/lessonbuilder/image/get/${id}`);
    }

    static addImage(model: LessonTopicPageImageCreateModel): Promise<IApiPostResult<LessonBuilderPageImageModel>> {
        return this.post<LessonBuilderPageImageModel>("/lessonbuilder/image/create", model);
    }

    static updateImage(model: LessonTopicPageImageEditModel): Promise<IApiPostResult<LessonBuilderPageImageModel>> {
        return this.put<LessonBuilderPageImageModel>("/lessonbuilder/image/edit", model);
    }

    static getTextbox(id: number): Promise<LessonTopicPageTextboxEditModel> {
        return this.get<LessonTopicPageTextboxEditModel>(`/lessonbuilder/textbox/get/${id}`);
    }

    static loadTextbox(id: number): Promise<LessonTopicPageTextboxCreateModel> {
        return this.get<LessonTopicPageTextboxCreateModel>(`/lessonbuilder/textbox/load/${id}`);
    }

    static addTextbox(
        model: LessonTopicPageTextboxCreateModel,
    ): Promise<IApiPostResult<LessonBuilderPageTextboxModel>> {
        return this.post<LessonBuilderPageTextboxModel>("/lessonbuilder/textbox/create", model);
    }

    static updateTextbox(
        model: LessonTopicPageTextboxEditModel,
    ): Promise<IApiPostResult<LessonBuilderPageTextboxModel>> {
        return this.put<LessonBuilderPageTextboxModel>("/lessonbuilder/textbox/edit", model);
    }

    static searchSnippets(
        model: LessonBuilderSnippetSearchModel,
    ): Promise<IApiPostResult<LessonBuilderSnippetSearchResult>> {
        return this.put<LessonBuilderSnippetSearchResult>("/lessonbuilder/snippets/search", model);
    }

    static getSnippet(id: number): Promise<LessonTopicPageSnippetEditModel> {
        return this.get<LessonTopicPageSnippetEditModel>(`/lessonbuilder/snippet/get/${id}`);
    }

    static addSnippet(
        model: LessonTopicPageSnippetCreateModel,
    ): Promise<IApiPostResult<LessonBuilderPageSnippetModel>> {
        return this.post<LessonBuilderPageSnippetModel>("/lessonbuilder/snippet/create", model);
    }

    static updateSnippet(
        model: LessonTopicPageSnippetEditModel,
    ): Promise<IApiPostResult<LessonBuilderPageSnippetModel>> {
        return this.put<LessonBuilderPageSnippetModel>("/lessonbuilder/snippet/edit", model);
    }

    static getSnippetHighlighting(id: number): Promise<LessonTopicPageSnippetHighlightingModel> {
        return this.get<LessonTopicPageSnippetHighlightingModel>(`/lessonbuilder/snippet/highlighting/${id}`);
    }

    static createSnippetHighlighting(
        model: LessonTopicPageSnippetHighlightingModel,
    ): Promise<IApiPostResult<LessonBuilderPageSnippetModel>> {
        return this.post<LessonBuilderPageSnippetModel>("/lessonbuilder/snippet/highlighting/create", model);
    }

    static getSnippetMultiHighlighting(id: number): Promise<LessonTopicPageSnippetMultiHighlightingModel> {
        return this.get<LessonTopicPageSnippetMultiHighlightingModel>(
            `/lessonbuilder/snippet/multi-highlighting/${id}`,
        );
    }

    static createSnippetMultiHighlighting(
        model: LessonTopicPageSnippetMultiHighlightingModel,
    ): Promise<IApiPostResult<LessonBuilderPageSnippetModel>> {
        return this.post<LessonBuilderPageSnippetModel>("/lessonbuilder/snippet/multi-highlighting/create", model);
    }

    static revertSnippet(id: number): Promise<IApiPostResult<LessonBuilderPageSnippetModel>> {
        return this.post<LessonBuilderPageSnippetModel>(`/lessonbuilder/snippet/revert/${id}`, {});
    }

    static convertSnippet(id: number, exerciseType: string): Promise<IApiPostResult<LessonBuilderPageSnippetModel>> {
        return this.post<LessonBuilderPageSnippetModel>(`/lessonbuilder/snippet/convert/${id}/${exerciseType}`, {});
    }

    static getSnippetExpansion(id: number): Promise<LessonTopicPageSnippetExpansionModel> {
        return this.get<LessonTopicPageSnippetExpansionModel>(`/lessonbuilder/snippet/expansion/${id}`);
    }

    static createSnippetExpansion(
        model: LessonTopicPageSnippetExpansionModel,
    ): Promise<IApiPostResult<LessonBuilderPageSnippetModel>> {
        return this.post<LessonBuilderPageSnippetModel>("/lessonbuilder/snippet/expansion/create", model);
    }

    static getImages(query: string) {
        return this.get<Array<ImageListItem>>(`/lessonbuilder/images/${query}`);
    }
}
