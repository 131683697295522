import * as yup from "yup";
import { LessonTopicPageImageModel, LessonTopicPageImageModelValidationRules } from "./LessonTopicPageImageModel";

export interface LessonTopicPageImageEditModel extends LessonTopicPageImageModel {
    ID: number;
    ImageUrl?: string;
}

export const LessonTopicPageImageEditModelValidationSchema = yup.object().shape<LessonTopicPageImageEditModel>({
    ID: yup.number().required(),
    Caption: LessonTopicPageImageModelValidationRules.Caption,
    ImageID: LessonTopicPageImageModelValidationRules.ImageID,
    ImageUrl: yup.string().optional(),
});
