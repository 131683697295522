import { FC, useState, useEffect, Fragment, useContext } from "react";
import clsx from "clsx";
import { LessonBuilderPageItem } from "../../models/lessonbuilder/pageitems/ILessonBuilderPageItem";
import { Constants } from "../../utils/Constants";
import { LessonBuilderPageItemDeleteModel } from "../../models/lessonbuilder/pageitems/LessonBuilderPageItemDeleteModel";
import { LessonBuilderService } from "../../services/LessonBuilderService";
import { Card, CardHeader, Button } from "reactstrap";
import { ArrowsMove, Pencil, Trash } from "react-bootstrap-icons";
import { LessonBuilderPageInstruction } from "./pageitems/LessonBuilderPageInstruction";
import { LessonBuilderPageSnippet } from "./pageitems/LessonBuilderPageSnippet";
import { LessonBuilderPageTextbox } from "./pageitems/LessonBuilderPageTextbox";
import { EditInstructionModal } from "./instructions/EditInstructionModal";
import { EditSnippetModal } from "./snippets/EditSnippetModal";
import { LessonBuilderContext } from "../../hooks/useLessonBuilder";
import { EditTextboxModal } from "./textboxes/EditTextboxModal";
import { LessonBuilderPageVideo } from "./pageitems/LessonBuilderPageVideo";
import { EditVideoModal } from "./videos/EditVideoModal";
import { LessonBuilderPageImage } from "./pageitems/LessonBuilderPageImage";
import { EditImageModal } from "./images/EditImageModal";

type Props = {
    item: LessonBuilderPageItem;
};

export const LessonBuilderItem: FC<Props> = (props: Props) => {
    const { item } = props;
    const { animationDuration } = Constants;

    const { deletePageItem } = useContext(LessonBuilderContext);

    const [deleting, setDeleting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [currentItem, setCurrentItem] = useState<LessonBuilderPageItem | null>(null);

    const remove = (i: LessonBuilderPageItem) => {
        if (currentItem) {
            if (window.confirm("This will remove the item - are you sure?")) {
                const model: LessonBuilderPageItemDeleteModel = {
                    Id: currentItem.ID,
                    TypeName: currentItem.TypeName,
                };

                LessonBuilderService.deletePageItem(model).then(() => {
                    setDeleting(true);

                    setTimeout(() => {
                        deletePageItem(i);
                    }, animationDuration);
                });
            }
        }
    };

    const startEditing = () => {
        setEditing(true);
    };

    const onCompleted = () => {
        setTimeout(() => {
            setEditing(false);
        }, 500);
    };

    const renderItem = (i: LessonBuilderPageItem) => {
        switch (i.Discriminator) {
            case "LessonTopicPageInstruction":
                return <LessonBuilderPageInstruction model={i} />;
            case "LessonTopicPageSnippet":
                return <LessonBuilderPageSnippet model={i} />;
            case "LessonTopicPageTextbox":
                return <LessonBuilderPageTextbox model={i} />;
            case "LessonTopicPageVideo":
                return <LessonBuilderPageVideo model={i} />;
            case "LessonTopicPageImage":
                return <LessonBuilderPageImage model={i} />;
        }
    };

    const renderEditor = (i: LessonBuilderPageItem) => {
        if (editing) {
            switch (i.Discriminator) {
                case "LessonTopicPageInstruction":
                    return <EditInstructionModal id={i.ID} onCompleted={onCompleted} />;
                case "LessonTopicPageSnippet":
                    return <EditSnippetModal id={i.ID} onCompleted={onCompleted} />;
                case "LessonTopicPageTextbox":
                    return <EditTextboxModal id={i.ID} onCompleted={onCompleted} />;
                case "LessonTopicPageVideo":
                    return <EditVideoModal id={i.ID} onCompleted={onCompleted} />;
                case "LessonTopicPageImage":
                    return <EditImageModal id={i.ID} onCompleted={onCompleted} />;
            }
        }
    };

    useEffect(() => {
        setCurrentItem(item);
    }, [item]);

    return (
        <Fragment>
            {currentItem && (
                <Card
                    key={`${currentItem.Discriminator}${currentItem.ID}`}
                    className={clsx("mb-4", "slide", (deleting || currentItem.AnimationAdding) && "slide-up")}
                >
                    <CardHeader>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <strong>{currentItem.ItemName}</strong>
                            </div>
                            <div>
                                <Button
                                    title="Drag to change the order of items on the page"
                                    type="button"
                                    size="sm"
                                    color="link"
                                    className="p-0 cursor-move"
                                    data-drag-handle
                                >
                                    <ArrowsMove />
                                </Button>{" "}
                                <Button
                                    type="button"
                                    size="sm"
                                    color="link"
                                    className="p-0"
                                    onClick={() => startEditing()}
                                >
                                    <Pencil />
                                </Button>{" "}
                                <Button
                                    type="button"
                                    size="sm"
                                    color="link"
                                    className="p-0"
                                    onClick={() => remove(currentItem)}
                                >
                                    <Trash />
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    {renderItem(currentItem)}
                </Card>
            )}
            {currentItem && renderEditor(currentItem)}
        </Fragment>
    );
};
