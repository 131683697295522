import { FC, useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { Constants } from "../../utils/Constants";
import { WrotevoteLogoHeader } from "../../components/wrotevote/WrotevoteLogoHeader";
import { WrotevoteLeaderboardModel } from "../../models/wrotevote/WrotevoteLeaderboardModel";
import { WrotevoteService } from "../../services/WrotevoteService";
import { Link, useHistory } from "react-router-dom";
import { RouteList } from "../../Routes";
import { Page } from "../Page";
import { useFormState } from "../../hooks/useFormState";
import { PageFadeAnimation } from "../../components/animation/PageFadeAnimation";
import { Loading } from "../../components/Loading";
import { WrotevoteReplyVote } from "../../components/wrotevote/WrotevoteReplyVote";
import { arrayToStringWithCommasAnd } from "../../utils/Helpers";
import { WrotevoteVotePercent } from "../../components/wrotevote/WrotevoteVotePercent";
import { useCharacters } from "../../hooks/useCharacters";

type Props = {
    id: string;
};

export const WrotevoteLeaderboardPage: FC<Props> = (props: Props) => {
    const { id } = props;
    const { setLoading, confirmServerError, loading } = useFormState(true);
    const { getRandomCharacters } = useCharacters();
    const history = useHistory();

    const [leaderboard, setLeaderboard] = useState<WrotevoteLeaderboardModel>();
    const [characters, setCharacters] = useState<string[]>([]);

    const loadData = useCallback(() => {
        setCharacters(getRandomCharacters());

        WrotevoteService.getLeaderboard(+id)
            .then((leaderboardModel) => {
                if (!leaderboardModel.Users.length) {
                    history.push(RouteList.WrotevoteCompare(id));
                } else {
                    setLeaderboard(leaderboardModel);
                    setLoading(false);
                }
            })
            .catch(confirmServerError);
    }, [getRandomCharacters, id, confirmServerError, history, setLoading]);

    useEffect(loadData, []);

    return (
        <Page className="page-wrotevote">
            <PageFadeAnimation show={loading}>
                <Loading />
            </PageFadeAnimation>

            <PageFadeAnimation show={leaderboard != null} anchorTop={true}>
                {leaderboard && (
                    <Container>
                        <WrotevoteLogoHeader />

                        <Row>
                            <Col sm="12">
                                <div className="text-center">
                                    <p className="mb-2">{leaderboard.Users.length === 1 ? "Our current winner is" : "Our current winners are"}</p>
                                    <p className="mb-5">
                                        <strong className="h1 font-weight-bold">{arrayToStringWithCommasAnd(leaderboard.Users.map((u) => u.Name))}</strong>
                                    </p>

                                    {leaderboard.Percentile && (
                                        <p>
                                            <small className="font-weight-bold">Nice work! Your responses were in the top {leaderboard.Percentile}%</small>
                                        </p>
                                    )}
                                    <div className="mb-5">
                                        {leaderboard.MoreVotesAvailable && (
                                            <p>
                                                <Link to={RouteList.WrotevoteCompare(id)} className="button">
                                                    Play another round
                                                </Link>
                                            </p>
                                        )}
                                        <a href={Constants.webUrl}>Back to lessons</a>
                                    </div>

                                    {leaderboard.MostPopularUserReply && (
                                        <Row>
                                            <Col lg={{ size: 5, offset: 1 }} md={{ size: 6, offset: 0 }} className="text-left">
                                                <h2 className="mb-5 h4 font-weight-bold">Your most popular reply so far</h2>

                                                <div className="h3 font-weight-bold">
                                                    <WrotevoteVotePercent
                                                        voteCount={leaderboard.MostPopularUserReply.VoteCount}
                                                        votePercent={leaderboard.MostPopularUserReply.VotePercent}
                                                    />
                                                </div>

                                                <div className="mb-5">
                                                    <small>{leaderboard.MostPopularUserReply.Prompt}</small>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="text-right pr-5">
                                                    <img alt="" className="peek" src={characters[0]} />
                                                </div>
                                                <div className="mb-5">
                                                    <WrotevoteReplyVote
                                                        showPrompt={false}
                                                        showUsername={false}
                                                        model={leaderboard.MostPopularUserReply}
                                                        showVotePercent={false}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    )}

                                    {leaderboard.Replies && (
                                        <Row>
                                            <Col md={{ size: 11, offset: 1 }}>
                                                <h2 className="mb-5 text-left h3 font-weight-bold">Popular replies</h2>
                                            </Col>

                                            <Col md={12}>
                                                {leaderboard.Replies.map((r, rIndex) => (
                                                    <Row key={r.ID}>
                                                        <Col md={{ size: 5, offset: 1 }} className="text-left pt-5">
                                                            <div className="font-weight-bold h4 mb-2 mt-4">{r.User}</div>

                                                            <div className="font-weight-bold mb-2">
                                                                <WrotevoteVotePercent voteCount={r.VoteCount} votePercent={r.VotePercent} />
                                                            </div>

                                                            <div className="mb-5">
                                                                <small>{r.Prompt}</small>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="text-right pr-5">
                                                                <img alt="" className="peek" src={characters[rIndex + 1]} />
                                                            </div>
                                                            <div className="mb-5">
                                                                <WrotevoteReplyVote showPrompt={false} showUsername={false} showVotePercent={false} model={r} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )}
            </PageFadeAnimation>
        </Page>
    );
};
