import { FC, useState, Fragment, useContext, useEffect, useCallback } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { useForm } from "react-hook-form";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { Loading } from "../../Loading";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import {
    LessonTopicPageTextboxCreateModel,
    LessonTopicPageTextboxCreateModelValidationSchema,
} from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxCreateModel";
import { SelectListItem } from "../../../models/SelectListItem";
import { FieldLessonTopicPageTextboxLabel } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxLabel";
import { FieldLessonTopicPageTextboxSnippet } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxSnippet";
import { FieldLessonTopicPageTextboxHighlightingDrawer } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxHighlightingDrawer";
import { FieldLessonTopicPageTextboxWordCategory } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxWordCategory";
import { FieldLessonTopicPageTextboxPreloadTextbox } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxPreloadTextbox";
import { FieldLessonTopicPageTextboxWrotevoteLabel } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxWrotevoteLabel";
import { FieldLessonTopicPageTextboxIsFeed } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsFeed";
import { FieldLessonTopicPageTextboxIsImageSideBySide } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsImageSideBySide";
import { FieldLessonTopicPageTextboxIsCombinable } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsCombinable";
import { FieldLessonTopicPageTextboxIsCombinedFromAllPrevious } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsCombinedIsCombinedFromAllPrevious";
import { FieldLessonTopicPageTextboxIsCombinedFromCurrentTopic } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsCombinedIsCombinedFromCurrentTopic";
import { FieldLessonTopicPageTextboxImage } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxImage";
import { yupResolver } from "@hookform/resolvers";
import { FieldLessonTopicPageTextboxReflectionLabel } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxReflectionLabel";
import { Hidden } from "../../fields/Hidden";
import { nameof } from "../../../utils/Helpers";

const nameLessonTopicPageID = nameof<LessonTopicPageTextboxCreateModel>("LessonTopicPageID");

export const AddTextboxModal: FC = () => {
    const [modal, setModal] = useState(false);
    const [lessonTopicPageSnippetIDSelectListOptions, setLessonTopicPageSnippetIDSelectListOptions] = useState<
        Array<SelectListItem>
    >([]);
    const [highlightingDrawerIDSelectListOptions, setHighlightingDrawerIDSelectListOptions] = useState<
        Array<SelectListItem>
    >([]);
    const [wordCategoryIDSelectListOptions, setWordCategoryIDSelectListOptions] = useState<Array<SelectListItem>>([]);
    const [preloadTextboxIDSelectListOptions, setPreloadTextboxIDSelectListOptions] = useState<Array<SelectListItem>>(
        [],
    );

    const { addPageItem, page } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const { register, handleSubmit, errors, reset, setValue } = useForm<LessonTopicPageTextboxCreateModel>({
        resolver: yupResolver(LessonTopicPageTextboxCreateModelValidationSchema),
    });

    const toggleModal = () => setModal(!modal);

    const onSubmit = handleSubmit(model => {
        setLoading(true);

        LessonBuilderService.addTextbox(model)
            .then(x => {
                addPageItem(x.Data);
                setModal(false);
                setLoading(false);
            })
            .catch(confirmServerError);
    });

    const loadData = useCallback(() => {
        if (page && modal) {
            LessonBuilderService.loadTextbox(page.ID)
                .then(x => {
                    reset(x);

                    x.LessonTopicPageSnippetIDSelectList &&
                        setLessonTopicPageSnippetIDSelectListOptions(x.LessonTopicPageSnippetIDSelectList);
                    x.HighlightingDrawerIDSelectList &&
                        setHighlightingDrawerIDSelectListOptions(x.HighlightingDrawerIDSelectList);
                    x.WordCategoryIDSelectList && setWordCategoryIDSelectListOptions(x.WordCategoryIDSelectList);
                    x.PreloadTextboxIDSelectList && setPreloadTextboxIDSelectListOptions(x.PreloadTextboxIDSelectList);

                    setLoading(false);
                })
                .catch(confirmServerError);
        }
    }, [confirmServerError, page, reset, setLoading, modal]);

    useEffect(loadData, [modal]);

    return (
        <Fragment>
            <Button type="button" onClick={toggleModal}>
                Add textbox
            </Button>

            <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true} size="lg">
                {loading ? (
                    <Loading />
                ) : (
                    page && (
                        <form onSubmit={onSubmit}>
                            <ModalHeader>Add textbox</ModalHeader>
                            <ModalBody>
                                <fieldset className="mb-4">
                                    <legend className="h6 font-weight-bold">Required fields</legend>

                                    <FieldLessonTopicPageTextboxLabel errors={errors} register={register} />

                                    <Hidden name={nameLessonTopicPageID} register={register} />
                                </fieldset>
                                <fieldset>
                                    <legend className="h6 font-weight-bold">Optional fields</legend>

                                    <FieldLessonTopicPageTextboxSnippet
                                        errors={errors}
                                        register={register}
                                        options={lessonTopicPageSnippetIDSelectListOptions}
                                    />

                                    <FieldLessonTopicPageTextboxImage
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                    />

                                    <FieldLessonTopicPageTextboxHighlightingDrawer
                                        register={register}
                                        errors={errors}
                                        options={highlightingDrawerIDSelectListOptions}
                                    />
                                    <FieldLessonTopicPageTextboxWordCategory
                                        register={register}
                                        errors={errors}
                                        options={wordCategoryIDSelectListOptions}
                                    />
                                    <FieldLessonTopicPageTextboxPreloadTextbox
                                        register={register}
                                        errors={errors}
                                        options={preloadTextboxIDSelectListOptions}
                                    />
                                    <FieldLessonTopicPageTextboxWrotevoteLabel register={register} errors={errors} />
                                    <FieldLessonTopicPageTextboxReflectionLabel register={register} errors={errors} />
                                    <FieldLessonTopicPageTextboxIsFeed register={register} errors={errors} />
                                    <FieldLessonTopicPageTextboxIsImageSideBySide register={register} errors={errors} />
                                    <FieldLessonTopicPageTextboxIsCombinable register={register} errors={errors} />
                                    <FieldLessonTopicPageTextboxIsCombinedFromAllPrevious
                                        register={register}
                                        errors={errors}
                                    />
                                    <FieldLessonTopicPageTextboxIsCombinedFromCurrentTopic
                                        register={register}
                                        errors={errors}
                                    />
                                </fieldset>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary">Add</Button>
                                <Button onClick={toggleModal}>Close</Button>
                            </ModalFooter>
                        </form>
                    )
                )}
            </Modal>
        </Fragment>
    );
};
