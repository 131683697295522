import { FC } from "react";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { LessonTopicPageTextboxModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxModel";
import { SelectListItem } from "../../../models/SelectListItem";
import { nameof } from "../../../utils/Helpers";
import { SelectList } from "../SelectList";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLSelectElement>(refOrValidationOptions: HTMLSelectElement) => void | ((ref: HTMLSelectElement | null) => void);
    options: Array<SelectListItem>;
};

export const FieldLessonTopicPageTextboxHighlightingDrawer: FC<Props> = (props: Props) => {
    const { errors, register, options } = props;

    const nameHighlightingDrawerID = nameof<LessonTopicPageTextboxModel>("HighlightingDrawerID");

    return (<SelectList
        name={nameHighlightingDrawerID}
        label="Highlighting drawer"
        register={register}
        errors={errors}
        options={options}
    />);
}