import { FC, useState, Fragment, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { nameof } from "../../../utils/Helpers";
import { Hidden } from "../../fields/Hidden";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { Loading } from "../../Loading";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import {
    LessonTopicPageInstructionCreateModel,
    LessonTopicPageInstructionCreateModelValidationSchema,
} from "../../../models/crud/lessontopicpageinstructions/LessonTopicPageInstructionCreateModel";
import { FieldLessonTopicPageInstructionBody } from "../../fields/lesson-topic-page-instructions/FieldLessonTopicPageInstructionBody";
import { FieldLessonTopicPageInstructionCollapsedText } from "../../fields/lesson-topic-page-instructions/FieldLessonTopicPageInstructionCollapsedText";

export const AddInstructionModal: FC = () => {
    const nameLessonTopicPageID = nameof<LessonTopicPageInstructionCreateModel>("LessonTopicPageID");
    
    const [modal, setModal] = useState(false);
    
    const { addPageItem, page } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState();

    const { register, handleSubmit, errors } = useForm<LessonTopicPageInstructionCreateModel>({
        resolver: yupResolver(LessonTopicPageInstructionCreateModelValidationSchema),
    });

    const toggleModal = () => setModal(!modal);

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.addInstruction(model)
            .then((x) => {
                addPageItem(x.Data);
                setModal(false);
                setLoading(false);
            })
            .catch(confirmServerError);
    });

    return (
        <Fragment>
            <Button type="button" onClick={toggleModal}>
                Add instruction
            </Button>

            <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
                {loading ? (
                    <Loading />
                ) : (
                    page && (
                        <form onSubmit={onSubmit}>
                            <ModalHeader>Add instruction</ModalHeader>
                            <ModalBody>
                                <Hidden name={nameLessonTopicPageID} value={page.ID} register={register} />
                                <FieldLessonTopicPageInstructionBody register={register} errors={errors} />
                                <FieldLessonTopicPageInstructionCollapsedText register={register} errors={errors} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary">Add</Button>
                                <Button type="button" onClick={toggleModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </form>
                    )
                )}
            </Modal>
        </Fragment>
    );
};
