import * as yup from "yup";
import { LessonTopicPageInstructionModel, LessonTopicPageInstructionModelValidationRules } from "./LessonTopicPageInstructionModel";

export interface LessonTopicPageInstructionCreateModel extends LessonTopicPageInstructionModel {
    LessonTopicPageID: number;
}

export const LessonTopicPageInstructionCreateModelValidationSchema = yup.object().shape<LessonTopicPageInstructionCreateModel>({
    LessonTopicPageID: yup.number().required(),
    Body: LessonTopicPageInstructionModelValidationRules.Body,
    CollapsedText: LessonTopicPageInstructionModelValidationRules.CollapsedText,
});
