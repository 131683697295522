import { FC } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { EditPageModal } from "./pages/EditPageModal";
import { EditTopicModal } from "./topics/EditTopicModal";

type Props = {
    lessonName: string;
    lessonId: number;
};

export const LessonBuilderBreadcrumbs: FC<Props> = (props: Props) => {
    const { lessonName } = props;

    return (
        <div className="mb-4">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <button type="button" className="btn btn-link p-0">
                            {lessonName}
                        </button>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <EditTopicModal />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <EditPageModal />
                    </BreadcrumbItem>
                </Breadcrumb>
        </div>
    );
};
