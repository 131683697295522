import * as yup from "yup";

export interface LessonTopicPageModel {
    Name: string;
    IsAssessable: boolean;
    LessonTopicID: number;
}

export const LessonTopicPageModelValidationRules = {
    Name: yup.string().required("Please enter a name").max(255, "Maximum 255 characters"),
    IsAssessable: yup.boolean().required(),
    LessonTopicID: yup.number().required().typeError("Please select a topic"),
};
