import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
        if (window.confirm("New version available.  Reload?")) {
            registration.unregister().then(() => {
                window.location.reload();
            });
        }
    },
});
