import { FC, useEffect, useCallback } from "react";
import clsx from "clsx";
import { SnippetHighlightingTag } from "../../models/highlighting/SnippetHighlightingTag";

type Props = {
    tags: Array<SnippetHighlightingTag>;
    highlightingEnabled: boolean;
    setSelectedTag: (value: React.SetStateAction<SnippetHighlightingTag | undefined>) => void;
    selectedTag: SnippetHighlightingTag | undefined;
};

export const HighlightingDrawer: FC<Props> = (props: Props) => {
    const { tags, highlightingEnabled, selectedTag, setSelectedTag } = props;

    const selectTag = useCallback(
        (tag: SnippetHighlightingTag) => {
            setSelectedTag(tag);
        },
        [setSelectedTag]
    );

    useEffect(() => {
        selectTag(tags[0]);
    }, [tags, selectTag]);

    return (
        <div className={clsx("highlighting-tags", !highlightingEnabled && "highlighting-tags--disabled")}>
            {tags.map((t) => {
                return (
                    selectedTag && (
                        <button
                            onClick={() => selectTag(t)}
                            type="button"
                            key={t.ID}
                            disabled={!highlightingEnabled || t.ID === selectedTag.ID}
                            style={{ ["--tag-colour" as any]: t.Colour }}
                            className={clsx("highlighting-tag", t.ID === selectedTag.ID && highlightingEnabled && "highlighting-tag--active")}
                        >
                            {t.Name}
                        </button>
                    )
                );
            })}
        </div>
    );
};
