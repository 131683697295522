import { FC } from "react";

type Props = {
    name: string;
    register: <HTMLInputElement>(refOrValidationOptions: HTMLInputElement) => void | ((ref: HTMLInputElement | null) => void);
    value?: string | number | null | undefined;
};

export const Hidden: FC<Props> = (props: Props) => {
    return <input className="d-none" defaultValue={props.value ?? ""} ref={(e) => props.register(e)} name={props.name} />;
};
