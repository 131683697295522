import { FC, useState, useCallback, useEffect, useContext, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { useForm } from "react-hook-form";
import { Hidden } from "../../fields/Hidden";
import { yupResolver } from "@hookform/resolvers";
import {
    LessonTopicPageSnippetEditModel,
    LessonTopicPageSnippetEditModelValidationSchema,
    SelectedSnippet,
} from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetEditModel";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { FieldLessonTopicPageSnippetSnippet } from "../../fields/lesson-topic-page-snippets/FieldLessonTopicPageSnippetSnippet";
import { Loading } from "../../Loading";

type Props = {
    id: number;
    onCompleted: () => void;
};

export const EditSnippetModal: FC<Props> = (props: Props) => {
    const { id, onCompleted } = props;

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const nameId = nameof<LessonTopicPageSnippetEditModel>("ID");
    const nameSnippetId = nameof<LessonTopicPageSnippetEditModel>("SnippetID");

    const [modal, setModal] = useState(true);
    const [currentSnippet, setCurrentSnippet] = useState<SelectedSnippet>();

    const { register, handleSubmit, reset, setValue } = useForm<LessonTopicPageSnippetEditModel>({
        resolver: yupResolver(LessonTopicPageSnippetEditModelValidationSchema),
    });

    const toggleModal = () => {
        if (modal) {
            onCompleted();
        }

        setModal(!modal);
    };

    const loadData = useCallback(() => {
        LessonBuilderService.getSnippet(+id)
            .then((x) => {
                reset(x);
                setCurrentSnippet(x.CurrentSnippet);
                setLoading(false);
            })
            .catch(confirmServerError);
    }, [confirmServerError, id, reset, setLoading]);

    useEffect(loadData, []);

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.updateSnippet(model)
            .then((x) => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
                onCompleted();
            })
            .catch(confirmServerError);
    });

    const selectSnippet = (id: number) => {
        setValue(nameSnippetId, id);
        onSubmit();
    };

    return (
        <Modal contentClassName="modal-content--tall" external={false} isOpen={modal} toggle={toggleModal} centered={true} size="xl" scrollable={true}>
            {loading ? (
                <Loading />
            ) : (
                <Fragment>
                    <ModalHeader>Edit snippet</ModalHeader>
                    <ModalBody>
                        <FieldLessonTopicPageSnippetSnippet loading={loading} currentSnippet={currentSnippet} selectSnippet={selectSnippet} />
                    </ModalBody>
                    <ModalFooter>
                        <form onSubmit={onSubmit}>
                            <Hidden name={nameSnippetId} register={register} />
                            <Hidden name={nameId} register={register} value={id} />
                        </form>
                        <Button type="button" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Fragment>
            )}
        </Modal>
    );
};
