import { FC, useCallback, useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { nameof } from "../../../utils/Helpers";
import { Hidden } from "../../fields/Hidden";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { useFormState } from "../../../hooks/useFormState";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    LessonTopicPageSnippetExpansionModel,
    LessonTopicPageSnippetPreviousExpansion,
} from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetExpansionModel";
import { useHighlighting } from "../../../hooks/useHighlighting";
import { Loading } from "../../Loading";
import { ComponentFadeAnimation } from "../../animation/ComponentFadeAnimation";
import { HighlightingDrawer } from "../../highlighting/HighlightingDrawer";
import { Snippet } from "../../Snippet";
import { SnippetHighlighting } from "../../highlighting/SnippetHighlighting";
import { expansionTags } from "../../../utils/WritelikeHelpers";

type Props = {
    id: number;
    onCompleted: () => void;
    body: string;
    isExample: boolean;
};

export const ExpansionModal: FC<Props> = (props: Props) => {
    const { id, onCompleted, body, isExample } = props;

    const nameId = nameof<LessonTopicPageSnippetExpansionModel>("ID");
    const nameJsonHighlighting = nameof<LessonTopicPageSnippetExpansionModel>("JSONHighlighting");
    const nameExerciseMode = nameof<LessonTopicPageSnippetExpansionModel>("ExerciseMode");
    const nameExpansionId = nameof<LessonTopicPageSnippetExpansionModel>("ExpansionID");

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const [modal, setModal] = useState(true);
    const [usingPreviousExpansion, setUsingPreviousExpansion] = useState(false);
    const [previousExpansions, setPreviousExpansions] = useState<Array<LessonTopicPageSnippetPreviousExpansion>>([]);

    const { register, handleSubmit, setValue, watch } = useForm<LessonTopicPageSnippetExpansionModel>({
        defaultValues: {
            ID: id,
        },
    });

    const snippetRef = useRef<HTMLElement>(null);

    const { selectedTag, setSelectedTag, highlightingEnabled, setHighlightingEnabled } = useHighlighting(snippetRef, {
        highlightedClass: "contracted",
        onUpdated: data => {
            setValue(nameJsonHighlighting, data);
        },
    });

    const toggleModal = () => {
        setModal(!modal);
    };

    const reuseExpansion = (p: LessonTopicPageSnippetPreviousExpansion) => {
        setValue(nameExpansionId, p.ID);
        onSubmit();
    };

    const loadData = useCallback(() => {
        LessonBuilderService.getSnippetExpansion(+id)
            .then(x => {
                setPreviousExpansions(x.PreviousExpansions);
                setHighlightingEnabled(true);
                setLoading(false);
            })
            .catch(confirmServerError);
    }, [confirmServerError, id, setLoading, setHighlightingEnabled]);

    useEffect(loadData, []);

    const onSubmit = handleSubmit(model => {
        setLoading(true);

        LessonBuilderService.createSnippetExpansion(model)
            .then(x => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
                onCompleted();
            })
            .catch(confirmServerError);
    });

    return (
        <Modal
            contentClassName="modal-content--tall"
            external={false}
            isOpen={modal}
            onClosed={onCompleted}
            toggle={toggleModal}
            centered={true}
            size="xl"
            scrollable={true}
        >
            <ModalHeader>Apply expansion</ModalHeader>
            <ModalBody>
                <form id="form" onSubmit={onSubmit}>
                    <div>
                        <Hidden name={nameId} register={register} />
                        <Hidden name={nameJsonHighlighting} register={register} />
                        <Hidden name={nameExpansionId} register={register} />

                        <ComponentFadeAnimation show={loading}>
                            <Loading />
                        </ComponentFadeAnimation>

                        <ComponentFadeAnimation show={!loading}>
                            <fieldset className="mb-4">
                                <legend className="sr-only">Show:</legend>
                                <FormGroup check inline>
                                    <Label check htmlFor="rbExample">
                                        <Input
                                            type="radio"
                                            id="rbExample"
                                            name={nameExerciseMode}
                                            value="Example"
                                            defaultChecked={true}
                                            innerRef={register}
                                        />{" "}
                                        Example
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check htmlFor="rbReveal">
                                        <Input
                                            type="radio"
                                            id="rbReveal"
                                            name={nameExerciseMode}
                                            value="Reveal"
                                            innerRef={register}
                                        />{" "}
                                        Reveal
                                    </Label>
                                </FormGroup>
                            </fieldset>

                            {previousExpansions.length > 0 && (
                                <div className="mb-4">
                                    {usingPreviousExpansion ? (
                                        <Button type="button" onClick={() => setUsingPreviousExpansion(false)}>
                                            Create new expansion
                                        </Button>
                                    ) : (
                                        <Button type="button" onClick={() => setUsingPreviousExpansion(true)}>
                                            Use existing expansion
                                        </Button>
                                    )}
                                </div>
                            )}

                            <div>
                                <ComponentFadeAnimation show={usingPreviousExpansion}>
                                    {previousExpansions &&
                                        previousExpansions.map(p => (
                                            <div key={p.ID} className="mb-4">
                                                <SnippetHighlighting
                                                    body={body}
                                                    json={p.JSONHighlighting}
                                                    tags={expansionTags}
                                                />

                                                <Button onClick={() => reuseExpansion(p)}>Use expansion</Button>
                                            </div>
                                        ))}
                                </ComponentFadeAnimation>

                                <ComponentFadeAnimation show={!usingPreviousExpansion}>
                                    <HighlightingDrawer
                                        tags={expansionTags}
                                        highlightingEnabled={highlightingEnabled}
                                        selectedTag={selectedTag}
                                        setSelectedTag={setSelectedTag}
                                    />
                                    <Snippet innerRef={snippetRef} body={body} isExample={isExample} />
                                </ComponentFadeAnimation>
                            </div>
                        </ComponentFadeAnimation>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                {watch(nameJsonHighlighting) !== "[]" && (
                    <Button form="form" color="primary">
                        Submit
                    </Button>
                )}
                <Button type="button" onClick={toggleModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};
