import { FC } from "react";
import clsx from "clsx";
import { Input, Label } from "reactstrap";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { CustomErrors } from "../CustomErrors";
import { HelperText } from "../HelperText";

type Props = {
    name: string;
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLInputElement>(
        refOrValidationOptions: HTMLInputElement,
    ) => void | ((ref: HTMLInputElement | null) => void);
    type?: "text" | "email" | "password" | "number" | "textarea" | "url";
    label?: string;
    id?: string;
    value?: string | number | null | undefined;
    hideLabel?: boolean;
    helperText?: string;
    maxLength?: number;
};

export const Textbox: FC<Props> = (props: Props) => {
    const { hideLabel, name, type, errors, value, register, helperText, maxLength } = props;

    const label = props.label ?? props.name;
    const id = props.id ?? props.name;

    const isValid = errors[name] == null;

    return (
        <div className="mb-3 w-100">
            <Label htmlFor={id} className={clsx(hideLabel && "sr-only")}>
                {label}
            </Label>
            <Input
                maxLength={maxLength}
                type={type}
                name={name}
                id={id}
                defaultValue={value ?? ""}
                innerRef={e => register(e)}
            />
            {helperText && isValid && <HelperText text={helperText} />}
            <CustomErrors errors={errors} name={name} />
        </div>
    );
};

Textbox.defaultProps = {
    type: "text",
    hideLabel: false,
};
