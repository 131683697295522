import { useState, useCallback, createContext } from "react";
import { LessonBuilderTopic, LessonBuilderTopicPage } from "../models/lessonbuilder/LessonBuilderPageModel";
import { LessonTopicPageEditModel } from "../models/crud/lessontopicpages/LessonTopicPageEditModel";
import { LessonTopicEditModel } from "../models/crud/lessontopics/LessonTopicEditModel";
import { LessonBuilderPageItem } from "../models/lessonbuilder/pageitems/ILessonBuilderPageItem";
import { Constants } from "../utils/Constants";
import { LessonBuilderService } from "../services/LessonBuilderService";

type SidebarMode = "Sort" | "Delete";

export const useLessonBuilder = () => {
    const { animationDuration } = Constants;

    const [lessonId, setLessonId] = useState(0);
    const [lessonName, setLessonName] = useState("");
    const [page, setPage] = useState<LessonTopicPageEditModel | null>(null);
    const [topic, setTopic] = useState<LessonTopicEditModel | null>(null);
    const [topics, setTopics] = useState<Array<LessonBuilderTopic>>([]);
    const [items, setItems] = useState<Array<LessonBuilderPageItem>>([]);
    const [sidebarMode, setSidebarMode] = useState<SidebarMode>("Sort");

    const setCurrentLessonId = useCallback((currentLessonId: number): void => {
        setLessonId(currentLessonId);
    }, []);

    const setCurrentLessonName = useCallback((currentLessonName: string): void => {
        setLessonName(currentLessonName);
    }, []);

    const setCurrentPage = useCallback((currentPage: LessonTopicPageEditModel): void => {
        setPage(currentPage);
    }, []);

    const setCurrentTopic = useCallback((currentTopic: LessonTopicEditModel): void => {
        setTopic(currentTopic);
    }, []);

    const setCurrentTopics = useCallback((currentTopics: Array<LessonBuilderTopic>): void => {
        setTopics(currentTopics);
    }, []);

    const setCurrentItems = useCallback((currentItems: Array<LessonBuilderPageItem>): void => {
        setItems(currentItems);
    }, []);

    const setCurrentSidebarMode = useCallback((currentMode: SidebarMode): void => {
        setSidebarMode(currentMode);
    }, []);

    const addPage = useCallback(
        (p: LessonBuilderTopicPage) => {
            p.AnimationAdding = true;

            const currentTopic = topics.find(t => t.ID === p.TopicID);

            if (currentTopic) {
                currentTopic.Pages = [...currentTopic.Pages, p];
                const updatedTopics = topics.map(t => (t.ID === currentTopic.ID ? currentTopic : t));

                setTopics(updatedTopics);

                setTimeout(() => {
                    setTopics(updatedTopics.map(t => ({ ...t, AnimationAdding: false })));
                });
            }
        },
        [topics],
    );

    const removePage = useCallback(
        (pageId: number) => {
            const newTopics = topics.map(t => ({
                ...t,
                Pages: t.Pages.filter(p => p.ID !== pageId),
            }));

            setTopics(newTopics);
        },
        [topics],
    );

    const addTopic = useCallback(
        (t: LessonBuilderTopic) => {
            t.AnimationAdding = true;

            const animatingItems = [...topics, t];

            setTopics(animatingItems);

            setTimeout(() => {
                setTopics(animatingItems.map(item => ({ ...item, AnimationAdding: false })));
            }, animationDuration);
        },
        [animationDuration, topics],
    );

    const removeTopic = useCallback(
        (topicId: number) => {
            const newTopics = topics.filter(t => t.ID !== topicId);

            setTopics(newTopics);
        },
        [topics],
    );

    const addPageItem = useCallback(
        (model: LessonBuilderPageItem) => {
            // first set the animation property of the new item and add it to the list
            model.AnimationAdding = true;

            const animatingItems = [...items, model];

            setItems(animatingItems);

            // next we remove the animation property after a short delay
            setTimeout(() => {
                setItems(animatingItems.map(item => ({ ...item, AnimationAdding: false })));
            }, animationDuration);
        },
        [animationDuration, items, setItems],
    );

    const updatePageItem = useCallback(
        (item: LessonBuilderPageItem) => {
            const newItems = items.map(i =>
                i.ID === item.ID && i.Discriminator === item.Discriminator ? { ...item } : i,
            );

            setItems(newItems);
        },
        [items],
    );

    const deletePageItem = useCallback(
        (item: LessonBuilderPageItem) => {
            setItems(items.filter(x => x !== item));
        },
        [items],
    );

    const updatePage = useCallback(
        (p: LessonTopicPageEditModel) => {
            setPage(p);

            // update the page name by ID
            setTopics(
                topics.map(topic => ({
                    ...topic,
                    Pages: topic.Pages.map(page => (page.ID === p.ID ? { ...page, Name: p.Name } : page)),
                })),
            );
        },
        [topics],
    );

    const updateTopic = useCallback(
        (t: LessonTopicEditModel) => {
            setTopic(t);

            // update the topic name by ID
            setTopics(topics.map(topic => (topic.ID === t.ID ? { ...topic, Name: t.Name } : topic)));
        },
        [topics],
    );

    const loadPage = useCallback(
        (id: number, setLoading: (l: boolean) => void) => {
            LessonBuilderService.getPage(+id).then(model => {
                setLessonName(model.LessonName);
                setCurrentLessonId(model.LessonID);
                setCurrentItems(model.Items);
                setCurrentTopics(model.Topics);
                setCurrentPage(model.PageModel);
                setCurrentTopic(model.TopicModel);
                setLoading(false);
            });
        },
        [setCurrentItems, setCurrentLessonId, setCurrentPage, setCurrentTopic, setCurrentTopics],
    );

    return {
        page,
        setCurrentPage,
        updatePage,
        topics,
        setCurrentTopics,
        updateTopic,
        setCurrentTopic,
        topic,
        lessonId,
        setCurrentLessonId,
        lessonName,
        setCurrentLessonName,
        items,
        setCurrentItems,
        addPageItem,
        updatePageItem,
        deletePageItem,
        addTopic,
        addPage,
        sidebarMode,
        setCurrentSidebarMode,
        removePage,
        removeTopic,
        loadPage,
    };
};

// automatic exports
export type ILessonBuilderContext = ReturnType<typeof useLessonBuilder>;
export const LessonBuilderContext = createContext<ILessonBuilderContext>({} as ILessonBuilderContext);
