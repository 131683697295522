import * as yup from "yup";
import { LessonTopicModel } from "./LessonTopicModel";

export interface LessonTopicEditModel extends LessonTopicModel {
    ID: number;
}

export const LessonTopicEditModelValidationSchema = yup.object().shape<LessonTopicEditModel>({
    ID: yup.number().required(),
    Name: yup.string().required("Please enter a name").max(255, "Maximum 255 characters"),
});
