import * as yup from "yup";
import { LessonTopicPageInstructionModel, LessonTopicPageInstructionModelValidationRules } from "./LessonTopicPageInstructionModel";

export interface LessonTopicPageInstructionEditModel extends LessonTopicPageInstructionModel {
    ID: number;
}

export const LessonTopicPageInstructionEditModelValidationSchema = yup.object().shape<LessonTopicPageInstructionEditModel>({
    ID: yup.number().required(),
    Body: LessonTopicPageInstructionModelValidationRules.Body,
    CollapsedText: LessonTopicPageInstructionModelValidationRules.CollapsedText,
});
