import * as yup from "yup";
import { LessonTopicModel } from "./LessonTopicModel";

export interface LessonTopicCreateModel extends LessonTopicModel {
    LessonID: number;
}

export const LessonTopicCreateModelValidationSchema = yup.object().shape<LessonTopicCreateModel>({
    Name: yup.string().required("Please enter a name").max(255, "Maximum 255 characters"),
    LessonID: yup.number().required(),
});
