import { DeepMap, FieldError, NestedValue, NonUndefined } from "@hookform/error-message/dist/types";
import { nameof } from "../../../utils/Helpers";
import { LessonTopicPageTextboxModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxModel";
import { UnpackNestedValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { DeepPartial, LiteralToPrimitive } from "react-hook-form/dist/types/utils";
import { ImageSelect } from "../ImageSelect";
import { ImageListItem } from "../../../models/ImageListItem";

export const FieldLessonTopicPageTextboxImage = (props: {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLInputElement>(
        refOrValidationOptions: HTMLInputElement,
    ) => void | ((ref: HTMLInputElement | null) => void);
    setValue(
        name: string,
        value?: NonUndefined<number> extends NestedValue<infer U>
            ? U
            : UnpackNestedValue<DeepPartial<LiteralToPrimitive<number>>>,
        options?: SetValueConfig,
    ): void;
    initialSelectedImage?: ImageListItem;
}) => {
    const { errors, register, setValue, initialSelectedImage } = props;

    const nameImageID = nameof<LessonTopicPageTextboxModel>("ImageID");

    return (
        <ImageSelect
            name={nameImageID}
            errors={errors}
            register={register}
            label="Inspiration image"
            setValue={setValue}
            initialSelectedImage={initialSelectedImage}
        />
    );
};
