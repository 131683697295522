import * as yup from "yup";
import { LessonTopicPageSnippetModel, LessonTopicPageSnippetModelValidationRules } from "./LessonTopicPageSnippetModel";

export interface LessonTopicPageSnippetEditModel extends LessonTopicPageSnippetModel {
    ID: number;
    CurrentSnippet?: SelectedSnippet;
}

export interface SelectedSnippet {
    Body: string;
    IsExample: boolean;
}

export const LessonTopicPageSnippetEditModelValidationSchema = yup.object().shape<LessonTopicPageSnippetEditModel>({
    ID: yup.number().required(),
    SnippetID: LessonTopicPageSnippetModelValidationRules.SnippetID,
    CurrentSnippet: yup.object<SelectedSnippet>().optional(),
});
