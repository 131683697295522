import { FC } from "react";
import { DeepMap, FieldError } from "@hookform/error-message/dist/types";
import { LessonTopicPageTextboxModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxModel";
import { nameof } from "../../../utils/Helpers";
import { Checkbox } from "../Checkbox";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLCheckboxElement>(refOrValidationOptions: HTMLCheckboxElement) => void | ((ref: HTMLCheckboxElement | null) => void);
};

export const FieldLessonTopicPageTextboxIsCombinedFromCurrentTopic: FC<Props> = (props: Props) => {
    const { errors, register } = props;

    const nameIsCombinedFromCurrentTopic = nameof<LessonTopicPageTextboxModel>("IsCombinedFromCurrentTopic");

    return (
        <Checkbox
            name={nameIsCombinedFromCurrentTopic}
            label="Whole - Current topic replies"
            register={register}
            errors={errors}
            helperText="Preloads with textbox replies from the current topic"
        />
    );
};
