import { FC, useState, useCallback, useEffect, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { Loading } from "../../Loading";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { useForm } from "react-hook-form";
import { Hidden } from "../../fields/Hidden";
import { yupResolver } from "@hookform/resolvers";
import {
    LessonTopicPageInstructionEditModel,
    LessonTopicPageInstructionEditModelValidationSchema,
} from "../../../models/crud/lessontopicpageinstructions/LessonTopicPageInstructionEditModel";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { FieldLessonTopicPageInstructionBody } from "../../fields/lesson-topic-page-instructions/FieldLessonTopicPageInstructionBody";
import { FieldLessonTopicPageInstructionCollapsedText } from "../../fields/lesson-topic-page-instructions/FieldLessonTopicPageInstructionCollapsedText";

type Props = {
    id: number;
    onCompleted: () => void;
};

export const EditInstructionModal: FC<Props> = (props: Props) => {
    const { id, onCompleted } = props;

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const nameId = nameof<LessonTopicPageInstructionEditModel>("ID");

    const [modal, setModal] = useState(true);

    const { errors, register, handleSubmit, reset } = useForm<LessonTopicPageInstructionEditModel>({
        resolver: yupResolver(LessonTopicPageInstructionEditModelValidationSchema),
    });

    const toggleModal = () => {
        if (modal) {
            onCompleted();
        }

        setModal(!modal);
    };

    const loadData = useCallback(() => {
        LessonBuilderService.getInstruction(+id)
            .then((x) => {
                reset(x);
                setLoading(false);
            })
            .catch(confirmServerError);
    }, [confirmServerError, id, reset, setLoading]);

    useEffect(loadData, []);

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.updateInstruction(model)
            .then((x) => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
                onCompleted();
            })
            .catch(confirmServerError);
    });

    return (
        <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
            {loading ? (
                <Loading />
            ) : (
                <form onSubmit={onSubmit}>
                    <ModalHeader>Edit instruction</ModalHeader>
                    <ModalBody>
                        <Hidden name={nameId} value={id} register={register} />
                        <FieldLessonTopicPageInstructionBody register={register} errors={errors} />
                        <FieldLessonTopicPageInstructionCollapsedText register={register} errors={errors} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary">Update</Button>
                        <Button type="button" onClick={toggleModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </form>
            )}
        </Modal>
    );
};
