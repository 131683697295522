import { FC } from "react";
import clsx from "clsx";
import { createMarkup } from "../../../utils/Helpers";
import { LessonBuilderPageInstructionModel } from "../../../models/lessonbuilder/pageitems/LessonBuilderPageInstructionModel";
import { CardBody } from "reactstrap";

type Props = {
    model: LessonBuilderPageInstructionModel;
};

export const LessonBuilderPageInstruction: FC<Props> = (props: Props) => {
    const { model } = props;

    return (
        <CardBody>
            <div className={clsx("alert", "alert-secondary", "mb-0")} dangerouslySetInnerHTML={createMarkup(model.Body)}></div>
        </CardBody>
    );
};
