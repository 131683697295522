import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { nameof } from "../../../utils/Helpers";
import { Textbox } from "../Textbox";
import { LessonTopicPageImageModel } from "../../../models/crud/lessontopicpageimages/LessonTopicPageImageModel";

const nameCaption = nameof<LessonTopicPageImageModel>("Caption");

export const FieldLessonTopicPageImageCaption = (props: {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLTextboxElement>(
        refOrValidationOptions: HTMLTextboxElement,
    ) => void | ((ref: HTMLTextboxElement | null) => void);
}) => {
    const { errors, register } = props;

    return <Textbox name={nameCaption} register={register} errors={errors} maxLength={255} />;
};
