import { FC, RefObject, useCallback, useEffect, useRef } from "react";
import { useHighlighting } from "../../hooks/useHighlighting";
import { SnippetHighlightingTag } from "../../models/highlighting/SnippetHighlightingTag";
import { Snippet } from "../Snippet";
import { HighlightingDrawer } from "./HighlightingDrawer";

type Props = {
    body: string;
    isExample?: boolean;
    json: string;
    tags: Array<SnippetHighlightingTag>;
    innerRef?: RefObject<HTMLElement>;
};

export const SnippetHighlighting: FC<Props> = (props: Props) => {
    const { body, isExample, json, tags, innerRef } = props;

    const snippetRef = useRef<HTMLElement>(innerRef?.current ?? null);
    const { setHighlighting, selectedTag, setSelectedTag, highlightingEnabled } = useHighlighting(snippetRef);

    const loadData = useCallback(() => {
        setHighlighting(json);
    }, [json, setHighlighting]);

    useEffect(loadData, [json]);

    return (
        <div>
            <HighlightingDrawer
                tags={tags}
                highlightingEnabled={highlightingEnabled}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
            />
            <Snippet innerRef={snippetRef} body={body} isExample={isExample} />
        </div>
    );
};

SnippetHighlighting.defaultProps = {
    isExample: false,
};
