import { createContext } from "react";
import Cookies from "js-cookie";

export const TokenKey = "token";

export const useGlobal = (): IGlobalContext => {
    const isAuthenticated = (): boolean => {
        return Cookies.get("writelike-auth") != null;
    };

    return {
        isAuthenticated,
    };
};

export interface IGlobalContext {
    isAuthenticated: () => boolean;
}

export const GlobalContext = createContext<IGlobalContext>({
    isAuthenticated: () => false,
});
