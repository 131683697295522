import { FC, useCallback, useContext, useEffect, useState, useRef, Fragment } from "react";
import {
    Button,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    FormGroup,
    Label,
} from "reactstrap";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { useFormState } from "../../../hooks/useFormState";
import {
    LessonTopicPageSnippetHighlightingDrawer,
    LessonTopicPageSnippetHighlightingModel,
    LessonTopicPageSnippetPreviousHighlighting,
} from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetHighlightingModel";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { ComponentFadeAnimation } from "../../animation/ComponentFadeAnimation";
import { Loading } from "../../Loading";
import { Snippet } from "../../Snippet";
import { useHighlighting } from "../../../hooks/useHighlighting";
import { HighlightingDrawer } from "../../highlighting/HighlightingDrawer";
import { useForm } from "react-hook-form";
import { nameof } from "../../../utils/Helpers";
import { Hidden } from "../../fields/Hidden";
import { SnippetHighlighting } from "../../highlighting/SnippetHighlighting";

type Props = {
    id: number;
    onCompleted: () => void;
    body: string;
    isExample: boolean;
};

export const HighlightingModal: FC<Props> = (props: Props) => {
    const { id, onCompleted, body, isExample } = props;

    const nameId = nameof<LessonTopicPageSnippetHighlightingModel>("ID");
    const nameExerciseMode = nameof<LessonTopicPageSnippetHighlightingModel>("ExerciseMode");
    const nameHighlightingId = nameof<LessonTopicPageSnippetHighlightingModel>("HighlightingID");
    const nameJsonHighlighting = nameof<LessonTopicPageSnippetHighlightingModel>("JSONHighlighting");
    const nameHighlightingTagsSubset = nameof<LessonTopicPageSnippetHighlightingModel>("HighlightingTagsSubset");
    const nameDrawerId = nameof<LessonTopicPageSnippetHighlightingModel>("HighlightingDrawerID");

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const [modal, setModal] = useState(true);
    const [allDrawers, setAllDrawers] = useState<Array<LessonTopicPageSnippetHighlightingDrawer>>([]);
    const [drawers, setDrawers] = useState<Array<LessonTopicPageSnippetHighlightingDrawer>>([]);
    const [drawerFilter, setDrawerFilter] = useState("");
    const [selectedDrawer, setSelectedDrawer] = useState<LessonTopicPageSnippetHighlightingDrawer>();
    const [requiredTags, setRequiredTags] = useState<Array<number>>([]);
    const [usingPreviousHighlighting, setUsingPreviousHighlighting] = useState(false);
    const [previousHighlightings, setPreviousHighlightings] = useState<
        Array<LessonTopicPageSnippetPreviousHighlighting>
    >([]);
    const [selectedPreviousHighlighting, setSelectedPreviousHighlighting] =
        useState<LessonTopicPageSnippetPreviousHighlighting>();

    const { register, reset, handleSubmit, setValue, watch, getValues } =
        useForm<LessonTopicPageSnippetHighlightingModel>({
            defaultValues: {
                ID: id,
                JSONHighlighting: "[]",
                HighlightingTagsSubset: [],
            },
        });

    const snippetRef = useRef<HTMLElement>(null);
    const previousRef = useRef<HTMLElement>(null);

    const {
        selectedTag,
        setSelectedTag,
        setHighlightingEnabled,
        clearHighlighting,
        highlightingEnabled,
        checkIfSnippetIsUsingTag,
    } = useHighlighting(snippetRef, {
        onUpdated: data => {
            setValue(nameJsonHighlighting, data);
        },
    });

    const toggleModal = () => {
        setModal(!modal);
    };

    const loadData = useCallback(() => {
        LessonBuilderService.getSnippetHighlighting(+id)
            .then(x => {
                setAllDrawers(x.Drawers);
                setPreviousHighlightings(x.PreviousHighlightings);
                setLoading(false);
            })
            .catch(confirmServerError);
    }, [confirmServerError, id, setLoading]);

    useEffect(loadData, []);

    const onSubmit = handleSubmit(model => {
        // before we call the service we need to remove non numbers from the array or the .NET model binder won't parse the model
        const fixedModel: LessonTopicPageSnippetHighlightingModel = {
            ...model,
            HighlightingTagsSubset: model.HighlightingTagsSubset.filter(x => x),
        };

        setLoading(true);

        LessonBuilderService.createSnippetHighlighting(fixedModel)
            .then(x => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
                onCompleted();
            })
            .catch(confirmServerError);
    });

    useEffect(() => {
        setDrawers(allDrawers.filter(d => d.Name.toLowerCase().indexOf(drawerFilter.toLowerCase()) > -1));
    }, [allDrawers, drawerFilter]);

    const selectDrawer = (drawer: LessonTopicPageSnippetHighlightingDrawer) => {
        setSelectedDrawer(drawer);
        setValue(nameDrawerId, drawer.ID);
        setHighlightingEnabled(true);
    };

    const changeHighlightingDrawer = () => {
        setSelectedDrawer(undefined);
        setValue(nameDrawerId, undefined);
        setHighlightingEnabled(false);
        clearHighlighting();
        setDrawerFilter("");
    };

    const lockTags = useCallback(
        (elementToCheck?: HTMLElement) => {
            // we need to disabled the tags which are already chosen
            const chosenTagIds = new Array<number | undefined>();

            if (selectedDrawer) {
                for (const t of selectedDrawer.Tags) {
                    if (checkIfSnippetIsUsingTag(t, elementToCheck)) {
                        chosenTagIds.push(t.ID);
                    } else {
                        chosenTagIds.push(undefined);
                    }
                }
            }

            const currentData = getValues();

            reset({ ...currentData, HighlightingTagsSubset: chosenTagIds });

            // lock the tags which are already chosen
            const allTags = chosenTagIds.filter(x => x != null) as Array<number>;
            setRequiredTags(allTags);
        },
        [checkIfSnippetIsUsingTag, getValues, reset, selectedDrawer],
    );

    const goToTagSubsetSelector = useCallback(() => {
        lockTags();

        setHighlightingEnabled(false);
    }, [lockTags, setHighlightingEnabled]);

    const reuseHighlighting = (p: LessonTopicPageSnippetPreviousHighlighting) => {
        setSelectedDrawer(p.Drawer);
        setValue(nameHighlightingId, p.ID);
        setSelectedPreviousHighlighting(p);

        // populate tags checkbox
        const tags = p.Drawer.Tags.map(x => x.ID);
        setRequiredTags(tags);

        const currentData = getValues();

        reset({ ...currentData, HighlightingTagsSubset: tags });
    };

    const lockFromPreviousHighlighting = useCallback(() => {
        if (previousRef.current) {
            lockTags(previousRef.current);
        }
    }, [lockTags]);

    useEffect(lockFromPreviousHighlighting, [selectedPreviousHighlighting]);

    return (
        <Modal
            contentClassName="modal-content--tall"
            external={false}
            isOpen={modal}
            onClosed={onCompleted}
            toggle={toggleModal}
            centered={true}
            size="xl"
            scrollable={true}
        >
            <ModalHeader>Apply highlighting</ModalHeader>
            <ModalBody>
                <form id="form" onSubmit={onSubmit}>
                    <div>
                        <ComponentFadeAnimation show={loading}>
                            <Loading />
                        </ComponentFadeAnimation>

                        <ComponentFadeAnimation show={!loading}>
                            <div className="mb-4">
                                <Hidden name={nameId} register={register} />
                                <Hidden name={nameJsonHighlighting} register={register} />
                                <Hidden name={nameDrawerId} register={register} />
                                <Hidden name={nameHighlightingId} register={register} />

                                <fieldset className="mb-4">
                                    <legend className="sr-only">Show:</legend>
                                    <FormGroup check inline>
                                        <Label check htmlFor="rbExample">
                                            <Input
                                                type="radio"
                                                id="rbExample"
                                                name={nameExerciseMode}
                                                value="Example"
                                                innerRef={register}
                                            />{" "}
                                            Example
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check htmlFor="rbReveal">
                                            <Input
                                                type="radio"
                                                id="rbReveal"
                                                defaultChecked={true}
                                                name={nameExerciseMode}
                                                value="Reveal"
                                                innerRef={register}
                                            />{" "}
                                            Reveal
                                        </Label>
                                    </FormGroup>
                                </fieldset>
                            </div>

                            <div>
                                <ComponentFadeAnimation show={selectedDrawer == null && !usingPreviousHighlighting}>
                                    <div>
                                        <div className="mb-4">
                                            <Row>
                                                <Col sm={4}>
                                                    <Input
                                                        placeholder="Filter drawers"
                                                        onChange={e => setDrawerFilter(e.target.value)}
                                                    />
                                                </Col>
                                                <Col sm={8} className="text-right">
                                                    {previousHighlightings.length > 0 && (
                                                        <Button
                                                            type="button"
                                                            onClick={() => setUsingPreviousHighlighting(true)}
                                                        >
                                                            Use existing highlighting
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>

                                        <Table hover={true}>
                                            <tbody>
                                                {drawers.map(d => (
                                                    <tr key={d.ID}>
                                                        <td className="td-tight">
                                                            <Button type="button" onClick={() => selectDrawer(d)}>
                                                                Use
                                                            </Button>
                                                        </td>
                                                        <td>{d.Name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </ComponentFadeAnimation>

                                <ComponentFadeAnimation show={selectedDrawer == null && usingPreviousHighlighting}>
                                    <div>
                                        <div className="mb-4">
                                            <Button onClick={() => setUsingPreviousHighlighting(false)}>
                                                Back to drawers
                                            </Button>
                                        </div>

                                        {previousHighlightings &&
                                            previousHighlightings.map(p => (
                                                <div key={p.ID} className="mb-4">
                                                    <SnippetHighlighting
                                                        body={body}
                                                        json={p.JSONHighlighting}
                                                        tags={p.Drawer.Tags}
                                                    />

                                                    <Button onClick={() => reuseHighlighting(p)}>
                                                        Use highlighting
                                                    </Button>
                                                </div>
                                            ))}
                                    </div>
                                </ComponentFadeAnimation>

                                <ComponentFadeAnimation show={selectedDrawer != null}>
                                    {selectedDrawer && (
                                        <Fragment>
                                            {selectedDrawer && selectedPreviousHighlighting ? (
                                                <SnippetHighlighting
                                                    body={body}
                                                    json={selectedPreviousHighlighting.JSONHighlighting}
                                                    innerRef={previousRef}
                                                    tags={selectedPreviousHighlighting.Drawer.Tags}
                                                />
                                            ) : (
                                                <Fragment>
                                                    <HighlightingDrawer
                                                        highlightingEnabled={highlightingEnabled}
                                                        tags={selectedDrawer.Tags}
                                                        selectedTag={selectedTag}
                                                        setSelectedTag={setSelectedTag}
                                                    />
                                                    <Snippet innerRef={snippetRef} body={body} isExample={isExample} />
                                                </Fragment>
                                            )}

                                            <div>
                                                <ComponentFadeAnimation show={requiredTags.length === 0}>
                                                    <Button
                                                        color="primary"
                                                        onClick={goToTagSubsetSelector}
                                                        disabled={watch(nameJsonHighlighting) === "[]"}
                                                    >
                                                        Confirm highlighting
                                                    </Button>{" "}
                                                    <Button onClick={changeHighlightingDrawer}>
                                                        Change highlighting drawer
                                                    </Button>
                                                </ComponentFadeAnimation>
                                            </div>

                                            <div>
                                                <ComponentFadeAnimation show={requiredTags.length > 0}>
                                                    <fieldset className="mb-4">
                                                        <legend>Show tags</legend>

                                                        {selectedDrawer.Tags.map((t, index) => (
                                                            <FormGroup check key={t.ID}>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        innerRef={register}
                                                                        name={`${nameHighlightingTagsSubset}[${index}]`}
                                                                        value={t.ID}
                                                                        disabled={
                                                                            requiredTags.find(x => x === t.ID) != null
                                                                        }
                                                                        // id="rbExample"
                                                                    />{" "}
                                                                    {t.Name}
                                                                </Label>
                                                            </FormGroup>
                                                        ))}
                                                    </fieldset>
                                                </ComponentFadeAnimation>
                                            </div>
                                        </Fragment>
                                    )}
                                </ComponentFadeAnimation>
                            </div>
                        </ComponentFadeAnimation>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                {requiredTags.length > 0 && (
                    <Button form="form" color="primary">
                        Submit
                    </Button>
                )}{" "}
                <Button type="button" onClick={toggleModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};
