import * as yup from "yup";
import { LessonTopicPageSnippetModel, LessonTopicPageSnippetModelValidationRules } from "./LessonTopicPageSnippetModel";

export interface LessonTopicPageSnippetCreateModel extends LessonTopicPageSnippetModel {
    LessonTopicPageID: number;
}

export const LessonTopicPageSnippetCreateModelValidationSchema = yup.object().shape<LessonTopicPageSnippetCreateModel>({
    LessonTopicPageID: yup.number().required(),
    SnippetID: LessonTopicPageSnippetModelValidationRules.SnippetID,
});
