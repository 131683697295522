import { FC, ReactNode } from "react";
import { CSSTransition } from "react-transition-group";
import { Constants } from "../../utils/Constants";

type Props = {
    show: boolean;
    children: ReactNode;
};

export const ComponentFadeAnimation: FC<Props> = (props: Props) => {
    const { animationDuration } = Constants;
    const { children, show } = props;

    return (
        <CSSTransition classNames="comp-trans" in={show} timeout={animationDuration} unmountOnExit>
            <div className="comp-trans">
                <div>{children}</div>
            </div>
        </CSSTransition>
    );
};
