import { FC } from "react";
import { DeepMap, FieldError } from "@hookform/error-message/dist/types";
import { LessonTopicPageTextboxModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxModel";
import { nameof } from "../../../utils/Helpers";
import { Textbox } from "../Textbox";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLTextboxElement>(
        refOrValidationOptions: HTMLTextboxElement,
    ) => void | ((ref: HTMLTextboxElement | null) => void);
};

export const FieldLessonTopicPageTextboxLabel: FC<Props> = (props: Props) => {
    const { errors, register } = props;

    const nameLabel = nameof<LessonTopicPageTextboxModel>("Label");

    return <Textbox name={nameLabel} register={register} errors={errors} />;
};
