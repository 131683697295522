import * as yup from "yup";

export interface LessonBuilderSnippetSearchModel {
    Query: string;
}

export interface LessonBuilderSnippetSearchResult {
    Items: Array<LessonBuilderSnippetSearchResultItem>;
}

export interface LessonBuilderSnippetSearchResultItem {
    ID: number;
    Body: string;
    IsExample: boolean;
}

export const LessonBuilderSnippetSearchModelValidationSchema = yup.object().shape<LessonBuilderSnippetSearchModel>({
    Query: yup.string().required(),
});
