import { FC } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { CustomErrors } from "../CustomErrors";
import { HelperText } from "../HelperText";

type Props = {
    name: string;
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLInputElement>(refOrValidationOptions: HTMLInputElement) => void | ((ref: HTMLInputElement | null) => void);
    label?: string;
    id?: string;
    value?: string | number | null | undefined;
    helperText?: string;
};

export const Checkbox: FC<Props> = (props: Props) => {
    const { name, errors, value, register, helperText } = props;

    const label = props.label ?? props.name;
    const id = props.id ?? props.name;

    const isValid = errors[name] == null;

    return (
        <div className="mb-3">
            <FormGroup check>
                <Label check htmlFor={id} className="mb-0">
                    <Input type="checkbox" name={name} id={id} defaultValue={value ?? ""} innerRef={(e) => register(e)} />
                    {label}
                </Label>
            </FormGroup>
            {helperText && isValid && <HelperText text={helperText} />}
            <CustomErrors errors={errors} name={name} />
        </div>
    );
};

Checkbox.defaultProps = {};
