import { FC, useState, useCallback, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import {
    LessonTopicPageTextboxEditModel,
    LessonTopicPageTextboxEditModelValidationSchema,
} from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxEditModel";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { ImageListItem } from "../../../models/ImageListItem";
import { SelectListItem } from "../../../models/SelectListItem";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Loading } from "../../Loading";
import { Hidden } from "../../fields/Hidden";
import { FieldLessonTopicPageTextboxHighlightingDrawer } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxHighlightingDrawer";
import { FieldLessonTopicPageTextboxImage } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxImage";
import { FieldLessonTopicPageTextboxIsCombinable } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsCombinable";
import { FieldLessonTopicPageTextboxIsFeed } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsFeed";
import { FieldLessonTopicPageTextboxIsImageSideBySide } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsImageSideBySide";
import { FieldLessonTopicPageTextboxLabel } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxLabel";
import { FieldLessonTopicPageTextboxPreloadTextbox } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxPreloadTextbox";
import { FieldLessonTopicPageTextboxWordCategory } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxWordCategory";
import { FieldLessonTopicPageTextboxWrotevoteLabel } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxWrotevoteLabel";
import { FieldLessonTopicPageTextboxReflectionLabel } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxReflectionLabel";
import { FieldLessonTopicPageTextboxIsCombinedFromAllPrevious } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsCombinedIsCombinedFromAllPrevious";
import { FieldLessonTopicPageTextboxIsCombinedFromCurrentTopic } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxIsCombinedIsCombinedFromCurrentTopic";
import { Snippet } from "../../Snippet";
import { FieldLessonTopicPageTextboxSnippet } from "../../fields/lesson-topic-page-textboxes/FieldLessonTopicPageTextboxSnippet";

type Props = {
    id: number;
    onCompleted: () => void;
};

export const EditTextboxModal: FC<Props> = (props: Props) => {
    const { id, onCompleted } = props;

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState(true);

    const nameId = nameof<LessonTopicPageTextboxEditModel>("ID");

    const [modal, setModal] = useState(true);
    const [lessonTopicPageSnippetIDSelectListOptions, setLessonTopicPageSnippetIDSelectListOptions] = useState<
        Array<SelectListItem>
    >([]);
    const [snippetBody, setSnippetBody] = useState("");
    const [highlightingDrawerIDSelectListOptions, setHighlightingDrawerIDSelectListOptions] = useState<
        Array<SelectListItem>
    >([]);
    const [wordCategoryIDSelectListOptions, setWordCategoryIDSelectListOptions] = useState<Array<SelectListItem>>([]);
    const [preloadTextboxIDSelectListOptions, setPreloadTextboxIDSelectListOptions] = useState<Array<SelectListItem>>(
        [],
    );
    const [initialSelectedImage, setInitialSelectedImage] = useState<ImageListItem>();

    const { errors, register, handleSubmit, reset, setValue } = useForm<LessonTopicPageTextboxEditModel>({
        resolver: yupResolver(LessonTopicPageTextboxEditModelValidationSchema),
    });

    const toggleModal = () => {
        if (modal) {
            onCompleted();
        }

        setModal(!modal);
    };

    const loadData = useCallback(() => {
        LessonBuilderService.getTextbox(+id)
            .then(x => {
                reset(x);

                x.LessonTopicPageSnippetIDSelectList &&
                    setLessonTopicPageSnippetIDSelectListOptions(x.LessonTopicPageSnippetIDSelectList);
                x.HighlightingDrawerIDSelectList &&
                    setHighlightingDrawerIDSelectListOptions(x.HighlightingDrawerIDSelectList);
                x.WordCategoryIDSelectList && setWordCategoryIDSelectListOptions(x.WordCategoryIDSelectList);
                x.PreloadTextboxIDSelectList && setPreloadTextboxIDSelectListOptions(x.PreloadTextboxIDSelectList);

                if (x.SnippetBody) {
                    setSnippetBody(x.SnippetBody);
                }

                setInitialSelectedImage(x.SelectedImage);

                setLoading(false);
            })
            .catch(confirmServerError);
    }, [confirmServerError, id, reset, setLoading]);

    useEffect(loadData, []);

    const onSubmit = handleSubmit(model => {
        setLoading(true);

        LessonBuilderService.updateTextbox(model)
            .then(x => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
                onCompleted();
            })
            .catch(confirmServerError);
    });

    return (
        <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true} size="lg">
            {loading ? (
                <Loading />
            ) : (
                <form onSubmit={onSubmit}>
                    <ModalHeader>Edit textbox</ModalHeader>
                    <ModalBody>
                        {snippetBody && <Snippet body={snippetBody} />}

                        <Hidden name={nameId} register={register} />
                        <fieldset className="mb-4">
                            <legend className="h6 font-weight-bold">Required fields</legend>

                            <FieldLessonTopicPageTextboxLabel errors={errors} register={register} />
                        </fieldset>
                        <fieldset>
                            <legend className="h6 font-weight-bold">Optional fields</legend>

                            <FieldLessonTopicPageTextboxSnippet
                                errors={errors}
                                register={register}
                                options={lessonTopicPageSnippetIDSelectListOptions}
                            />

                            <FieldLessonTopicPageTextboxImage
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                initialSelectedImage={initialSelectedImage}
                            />

                            <FieldLessonTopicPageTextboxHighlightingDrawer
                                register={register}
                                errors={errors}
                                options={highlightingDrawerIDSelectListOptions}
                            />
                            <FieldLessonTopicPageTextboxWordCategory
                                register={register}
                                errors={errors}
                                options={wordCategoryIDSelectListOptions}
                            />
                            <FieldLessonTopicPageTextboxPreloadTextbox
                                register={register}
                                errors={errors}
                                options={preloadTextboxIDSelectListOptions}
                            />
                            <FieldLessonTopicPageTextboxWrotevoteLabel register={register} errors={errors} />
                            <FieldLessonTopicPageTextboxReflectionLabel register={register} errors={errors} />
                            <FieldLessonTopicPageTextboxIsFeed register={register} errors={errors} />
                            <FieldLessonTopicPageTextboxIsImageSideBySide register={register} errors={errors} />
                            <FieldLessonTopicPageTextboxIsCombinable register={register} errors={errors} />
                            <FieldLessonTopicPageTextboxIsCombinedFromAllPrevious register={register} errors={errors} />
                            <FieldLessonTopicPageTextboxIsCombinedFromCurrentTopic
                                register={register}
                                errors={errors}
                            />
                        </fieldset>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary">Save</Button>
                        <Button onClick={toggleModal}>Close</Button>
                    </ModalFooter>
                </form>
            )}
        </Modal>
    );
};
