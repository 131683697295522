import { FC } from "react";
import clsx from "clsx";
import { Input, Label } from "reactstrap";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { CustomErrors } from "../CustomErrors";
import { SelectListItem } from "../../models/SelectListItem";
import { HelperText } from "../HelperText";

type Props = {
    name: string;
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLInputElement>(refOrValidationOptions: HTMLInputElement) => void | ((ref: HTMLInputElement | null) => void);
    options: Array<SelectListItem>;
    label?: string;
    id?: string;
    value?: string | number | null | undefined;
    hideLabel?: boolean;
    defaultText?: string;
    helperText?: string;
};

export const SelectList: FC<Props> = (props: Props) => {
    const { hideLabel, name, errors, value, register, options, defaultText, helperText } = props;

    const label = props.label ?? props.name;
    const id = props.id ?? props.name;

    const isValid = errors[name] == null;

    return (
        <div className="mb-3 w-100">
            <Label htmlFor={id} className={clsx(hideLabel && "sr-only")}>
                {label}
            </Label>
            <Input type="select" name={name} id={id} defaultValue={value ?? ""} innerRef={(e) => register(e)}>
                <option value="">{defaultText}</option>
                {options.map((o) => (
                    <option key={o.Value} value={o.Value}>
                        {o.Text}
                    </option>
                ))}
            </Input>
            {helperText && isValid && <HelperText text={helperText} />}
            <CustomErrors errors={errors} name={name} />
        </div>
    );
};

SelectList.defaultProps = {
    hideLabel: false,
};
