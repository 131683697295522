import * as yup from "yup";
import { LessonTopicPageVideoModel, LessonTopicPageVideoModelValidationRules } from "./LessonTopicPageVideoModel";

export interface LessonTopicPageVideoEditModel extends LessonTopicPageVideoModel {
    ID: number;
}

export const LessonTopicPageVideoEditModelValidationSchema = yup.object().shape<LessonTopicPageVideoEditModel>({
    ID: yup.number().required(),
    VideoID: LessonTopicPageVideoModelValidationRules.VideoID,
});
