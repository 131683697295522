import { FC } from "react";
import { DeepMap, FieldError } from "@hookform/error-message/dist/types";
import { LessonTopicPageTextboxModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxModel";
import { nameof } from "../../../utils/Helpers";
import { Checkbox } from "../Checkbox";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLCheckboxElement>(refOrValidationOptions: HTMLCheckboxElement) => void | ((ref: HTMLCheckboxElement | null) => void);
};

export const FieldLessonTopicPageTextboxIsCombinable: FC<Props> = (props: Props) => {
    const { errors, register } = props;

    const nameIsCombinable = nameof<LessonTopicPageTextboxModel>("IsCombinable");

    return <Checkbox name={nameIsCombinable} label="Part" register={register} errors={errors} helperText="Can be combined into a later textbox" />;
};
