import { FC } from "react";
import { CardBody } from "reactstrap";
import { LessonBuilderPageVideoModel } from "../../../models/lessonbuilder/pageitems/LessonBuilderPageVideoModel";

type Props = {
    model: LessonBuilderPageVideoModel;
};

export const LessonBuilderPageVideo: FC<Props> = (props: Props) => {
    const { model } = props;

    return (
        <CardBody>
            <div className="embed-responsive embed-responsive-16by9">
                <iframe
                    className="embed-responsive-item"
                    src={`https://www.youtube.com/embed/${model.VideoID}?rel=0`}
                    allowFullScreen
                ></iframe>
            </div>
        </CardBody>
    );
};
