import { FC } from "react";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { LessonTopicPageTextboxCreateModel } from "../../../models/crud/lessontopicpagetextboxes/LessonTopicPageTextboxCreateModel";
import { SelectListItem } from "../../../models/SelectListItem";
import { nameof } from "../../../utils/Helpers";
import { SelectList } from "../SelectList";

type Props = {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLSelectElement>(
        refOrValidationOptions: HTMLSelectElement,
    ) => void | ((ref: HTMLSelectElement | null) => void);
    options: Array<SelectListItem>;
};

export const FieldLessonTopicPageTextboxSnippet: FC<Props> = (props: Props) => {
    const { errors, register, options } = props;

    const nameLessonTopicPageSnippetID = nameof<LessonTopicPageTextboxCreateModel>("LessonTopicPageSnippetID");

    return (
        <SelectList
            name={nameLessonTopicPageSnippetID}
            label="Snippet reference"
            register={register}
            errors={errors}
            options={options}
        />
    );
};
