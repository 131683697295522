import { useCallback, useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { ReactSortable } from "react-sortablejs";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { LessonTopicPageSnippetPreviousHighlighting } from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetHighlightingModel";
import {
    LessonTopicPageSnippetMultiHighlightingItem,
    LessonTopicPageSnippetMultiHighlightingModel,
} from "../../../models/crud/lessontopicpagesnippets/LessonTopicPageSnippetMultiHighlightingModel";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { ComponentFadeAnimation } from "../../animation/ComponentFadeAnimation";
import { Hidden } from "../../fields/Hidden";
import { SnippetHighlighting } from "../../highlighting/SnippetHighlighting";
import { Loading } from "../../Loading";

type Props = {
    id: number;
    body: string;
    onCompleted: () => void;
};

export const MultiHighlightingModal = (props: Props) => {
    const { id, onCompleted, body } = props;

    const nameId = nameof<LessonTopicPageSnippetMultiHighlightingModel>("ID");
    const nameItems = nameof<LessonTopicPageSnippetMultiHighlightingModel>("Items");

    const [modal, setModal] = useState(true);
    const [items, setItems] = useState<Array<LessonTopicPageSnippetMultiHighlightingItem>>();

    const { setLoading, confirmServerError, loading } = useFormState(true);

    const { updatePageItem } = useContext(LessonBuilderContext);
    const { register, handleSubmit } = useForm<LessonTopicPageSnippetMultiHighlightingModel>({
        defaultValues: {
            ID: id,
        },
    });

    // const [previousHighlightings, setPreviousHighlightings] =
    //     useState<Array<LessonTopicPageSnippetPreviousHighlighting>>();

    const toggleModal = () => {
        setModal(!modal);
    };

    const loadData = useCallback(() => {
        LessonBuilderService.getSnippetMultiHighlighting(id)
            .then(x => {
                setItems(x.Items);
                setLoading(false);
            })
            .catch(confirmServerError);
    }, [id, setLoading]);

    useEffect(loadData, [loadData]);

    const onSubmit = handleSubmit(model => {
        if (items) {
            setLoading(true);

            model.Items = items.map(i => ({ ...i, PreviousHighlighting: undefined }));

            LessonBuilderService.createSnippetMultiHighlighting(model).then(x => {
                updatePageItem(x.Data);
                toggleModal();
                setLoading(false);
            });
        }
    });

    return (
        <Modal
            contentClassName="modal-content--tall"
            external={false}
            isOpen={modal}
            onClosed={onCompleted}
            toggle={toggleModal}
            centered={true}
            size="xl"
            scrollable={true}
        >
            <ModalHeader>Create multi highlighting</ModalHeader>
            <ModalBody>
                {items == null ? (
                    <Loading />
                ) : (
                    <form onSubmit={onSubmit}>
                        <div>
                            <ComponentFadeAnimation show={loading}>
                                <Loading />
                            </ComponentFadeAnimation>

                            <ComponentFadeAnimation show={!loading}>
                                <div>
                                    <Hidden name={nameId} register={register} />

                                    {items.length > 0 ? (
                                        <div className="d-flex gap-3 flex-column">
                                            <div>
                                                <button className="btn btn-primary">Save</button>
                                            </div>
                                            <div>
                                                <ReactSortable
                                                    list={items}
                                                    setList={setItems}
                                                    className="list-group"
                                                    handle=".btn-secondary"
                                                >
                                                    {items.map(p => {
                                                        const itemId = `chk${p.HighlightingID}`;

                                                        return (
                                                            <div
                                                                key={p.HighlightingID}
                                                                className="list-group-item d-flex flex-column gap-3"
                                                            >
                                                                <div className="flex-row d-flex gap-3">
                                                                    <div className="form-check">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={itemId}
                                                                            className="form-check-input"
                                                                            checked={p.IsSelected}
                                                                            onChange={() => {
                                                                                setItems([
                                                                                    ...items.map(item => {
                                                                                        if (
                                                                                            item.HighlightingID ==
                                                                                            p.HighlightingID
                                                                                        ) {
                                                                                            return {
                                                                                                ...item,
                                                                                                IsSelected:
                                                                                                    !item.IsSelected,
                                                                                            };
                                                                                        }

                                                                                        return item;
                                                                                    }),
                                                                                ]);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary cursor-move"
                                                                            disabled={!p.IsSelected}
                                                                        >
                                                                            Sort
                                                                        </button>
                                                                    </div>

                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={p.Name}
                                                                            disabled={!p.IsSelected}
                                                                            onChange={e => {
                                                                                setItems([
                                                                                    ...items.map(item => {
                                                                                        if (
                                                                                            item.HighlightingID ==
                                                                                            p.HighlightingID
                                                                                        ) {
                                                                                            return {
                                                                                                ...item,
                                                                                                Name: e.target.value,
                                                                                            };
                                                                                        }

                                                                                        return item;
                                                                                    }),
                                                                                ]);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {p.IsSelected && p.PreviousHighlighting && (
                                                                    <SnippetHighlighting
                                                                        body={body}
                                                                        json={p.PreviousHighlighting.JSONHighlighting}
                                                                        tags={p.PreviousHighlighting.Drawer.Tags}
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </ReactSortable>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="alert alert-warning">
                                            This snippet has no previous highlighting.
                                        </div>
                                    )}
                                </div>
                            </ComponentFadeAnimation>
                        </div>
                    </form>
                )}
            </ModalBody>
        </Modal>
    );
};
