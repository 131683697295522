import * as yup from "yup";
import { ImageListItem } from "../../ImageListItem";
import { SelectListItem } from "../../SelectListItem";

export interface LessonTopicPageTextboxModel {
    Label: string;
    LessonTopicPageSnippetID?: number;
    WrotevoteLabel?: string;
    ReflectionLabel?: string;
    IsCombinable: boolean;
    IsCombinedFromAllPrevious: boolean;
    IsCombinedFromCurrentTopic: boolean;
    IsFeed: boolean;
    IsImageSideBySide: boolean;
    HighlightingDrawerID?: number;
    HighlightingDrawerIDSelectList?: Array<SelectListItem>;
    WordCategoryID?: number;
    WordCategoryIDSelectList?: Array<SelectListItem>;
    PreloadTextboxID?: number;
    PreloadTextboxIDSelectList?: Array<SelectListItem>;
    ImageID?: number;
    LessonTopicPageSnippetIDSelectList?: Array<SelectListItem>;
}

export const LessonTopicPageTextboxModelValidationRules = {
    Label: yup.string().required(),
    IsCombinable: yup.boolean().required(),
    IsCombinedFromAllPrevious: yup.boolean().required(),
    IsCombinedFromCurrentTopic: yup.boolean().required(),
    IsFeed: yup.boolean().required(),
    IsImageSideBySide: yup.boolean().required(),
}