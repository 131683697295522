import { FC } from "react";
import { WrotevoteLogoHeader } from "../../components/wrotevote/WrotevoteLogoHeader";
import { Link } from "react-router-dom";
import { RouteList } from "../../Routes";
import { Constants } from "../../utils/Constants";
import { Page } from "../Page";
import { HalfPageColumn } from "../../components/HalfPageColumn";
import CharacterHeads from "../../assets/img/wrotevote/character-heads.svg";
import { Row } from "reactstrap";

type Props = {
    id: string;
};

export const WrotevoteWelcomePage: FC<Props> = (props: Props) => {
    const { id } = props;

    return (
        <Page className="page-wrotevote page-wrotevote--bottom" style={{ backgroundImage: `url('${CharacterHeads}')` }}>
            <Row>
                <HalfPageColumn>
                    <div className="text-center">
                        <WrotevoteLogoHeader size="lg" />
                        <p className="lead mb-4">Wrotevote is a game where you vote on your favourite snippet replies from other users in your group.</p>

                        <div className="mb-4">
                            <Link className="button" to={RouteList.WrotevoteCompare(id)}>
                                Start game
                            </Link>
                        </div>
                        <div>
                            <a href={Constants.webUrl}>Back to lessons</a>
                        </div>
                    </div>
                </HalfPageColumn>
            </Row>
        </Page>
    );
};
