import * as yup from "yup";
import { LessonTopicPageImageModel, LessonTopicPageImageModelValidationRules } from "./LessonTopicPageImageModel";

export interface LessonTopicPageImageCreateModel extends LessonTopicPageImageModel {
    LessonTopicPageID: number;
}

export const LessonTopicPageImageCreateModelValidationSchema = yup.object().shape<LessonTopicPageImageCreateModel>({
    LessonTopicPageID: yup.number().required(),
    Caption: LessonTopicPageImageModelValidationRules.Caption,
    ImageID: LessonTopicPageImageModelValidationRules.ImageID,
});
