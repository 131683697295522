import * as yup from "yup";
import { LessonTopicPageTextboxModel, LessonTopicPageTextboxModelValidationRules } from "./LessonTopicPageTextboxModel";
import { ImageListItem } from "../../ImageListItem";

export interface LessonTopicPageTextboxEditModel extends LessonTopicPageTextboxModel {
    ID: number;
    SnippetBody?: string;
    SelectedImage?: ImageListItem;
}

export const LessonTopicPageTextboxEditModelValidationSchema = yup.object().shape<LessonTopicPageTextboxEditModel>({
    IsImageSideBySide: LessonTopicPageTextboxModelValidationRules.IsImageSideBySide,
    Label: LessonTopicPageTextboxModelValidationRules.Label,
    IsCombinable: LessonTopicPageTextboxModelValidationRules.IsCombinable,
    IsCombinedFromCurrentTopic: LessonTopicPageTextboxModelValidationRules.IsCombinedFromCurrentTopic,
    IsCombinedFromAllPrevious: LessonTopicPageTextboxModelValidationRules.IsCombinedFromAllPrevious,
    IsFeed: LessonTopicPageTextboxModelValidationRules.IsFeed,
    ID: yup.number().required(),
});
