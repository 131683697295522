import { CardBody } from "reactstrap";
import { LessonBuilderPageImageModel } from "../../../models/lessonbuilder/pageitems/LessonBuilderPageImageModel";

export const LessonBuilderPageImage = (props: { model: LessonBuilderPageImageModel }) => {
    const { model } = props;

    return (
        <CardBody>
            <img alt="" src={model.ImageUrl} className="img-fluid" />

            {model.Caption && (
                <div className="mt-3">
                    <blockquote className="blockquote">{model.Caption}</blockquote>
                </div>
            )}
        </CardBody>
    );
};
