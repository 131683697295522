import { FC, Fragment } from "react";
import { Constants } from "../utils/Constants";

export const HomePage: FC = () => {
    const { webUrl } = Constants;

    window.location.href = webUrl;

    return <Fragment></Fragment>;
};
