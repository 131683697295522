import { FC, Fragment, useState, useContext, useEffect } from "react";
import { LessonTopicEditModel, LessonTopicEditModelValidationSchema } from "../../../models/crud/lessontopics/LessonTopicEditModel";
import { nameof } from "../../../utils/Helpers";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { useFormState } from "../../../hooks/useFormState";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Loading } from "../../Loading";
import { Textbox } from "../../fields/Textbox";
import { Hidden } from "../../fields/Hidden";

export const EditTopicModal: FC = () => {
    const [modal, setModal] = useState(false);

    const nameId = nameof<LessonTopicEditModel>("ID");
    const nameName = nameof<LessonTopicEditModel>("Name");

    const { topic, updateTopic } = useContext(LessonBuilderContext);

    const toggleModal = () => setModal(!modal);

    const { setLoading, confirmServerError, loading, handleFormResponse } = useFormState();

    const { errors, register, handleSubmit, setError, reset } = useForm<LessonTopicEditModel>({
        resolver: yupResolver(LessonTopicEditModelValidationSchema),
    });

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.editTopic(model)
            .then((x) => {
                if (x.Success) {
                    setModal(false);
                    updateTopic(model);
                }

                handleFormResponse(x.Success, x.Errors, setError);
            })
            .catch(confirmServerError);
    });

    useEffect(() => {
        if (topic) {
            reset(topic);
        }
    }, [topic, reset]);

    return (
        <Fragment>
            {topic && (
                <button type="button" className="btn btn-link p-0" onClick={toggleModal}>
                    {topic.Name}
                </button>
            )}
            <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
                {loading ? (
                    <Loading />
                ) : (
                    topic && (
                        <form onSubmit={onSubmit}>
                            <ModalHeader>Update topic</ModalHeader>
                            <ModalBody>
                                <Textbox name={nameName} errors={errors} register={register} label="Name" value={topic.Name} />
                                <Hidden name={nameId} register={register} value={topic.ID} />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary">Update</Button>
                                <Button color="secondary" type="button" onClick={() => setModal(false)}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </form>
                    )
                )}
            </Modal>
        </Fragment>
    );
};
