import { FieldError, NestedValue } from "react-hook-form";
import { UnpackNestedValue, SetValueConfig } from "react-hook-form/dist/types/form";
import { DeepMap, NonUndefined, DeepPartial, LiteralToPrimitive } from "react-hook-form/dist/types/utils";
import { ImageListItem } from "../../../models/ImageListItem";
import { nameof } from "../../../utils/Helpers";
import { ImageSelect } from "../ImageSelect";
import { LessonTopicPageImageModel } from "../../../models/crud/lessontopicpageimages/LessonTopicPageImageModel";

const nameImageID = nameof<LessonTopicPageImageModel>("ImageID");

export const FieldLessonTopicPageImageImageID = (props: {
    errors: DeepMap<Record<string, any>, FieldError>;
    register: <HTMLInputElement>(
        refOrValidationOptions: HTMLInputElement,
    ) => void | ((ref: HTMLInputElement | null) => void);
    setValue(
        name: string,
        value?: NonUndefined<number> extends NestedValue<infer U>
            ? U
            : UnpackNestedValue<DeepPartial<LiteralToPrimitive<number>>>,
        options?: SetValueConfig,
    ): void;
    initialSelectedImage?: ImageListItem;
}) => {
    const { errors, register, setValue, initialSelectedImage } = props;

    return (
        <ImageSelect
            name={nameImageID}
            errors={errors}
            register={register}
            label="Image"
            setValue={setValue}
            initialSelectedImage={initialSelectedImage}
        />
    );
};
