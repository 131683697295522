import { RefObject, useState, useCallback, useEffect } from "react";
import { deserializeHighlights, doHighlight, serializeHighlights, removeHighlights } from "@funktechno/texthighlighter/lib";
import { hlDescriptorI } from "@funktechno/texthighlighter/lib/TextHighlighterUtils";
import { SnippetHighlightingTag } from "../models/highlighting/SnippetHighlightingTag";

// TODO: name this properly
type StrongArray = [string, string, string, number, number];

type Props = {
    onUpdated: (data: string) => void;
    highlightedClass: string;
};

export const useHighlighting = (el: RefObject<HTMLElement>, props?: Partial<Props>) => {
    const dataHighlightTagId = "data-highlight-tagid";

    

    const [tags, setTags] = useState<Array<SnippetHighlightingTag>>([]);
    const [selectedTag, setSelectedTag] = useState<SnippetHighlightingTag>();
    const [highlightingEnabled, setHighlightingEnabled] = useState(false);
    const [highlightingData, setHighlightingData] = useState("");

    const getHighlightingData = useCallback(() => {
        if (el.current) {
            var data = serializeHighlights(el.current);

            if (data) {
                const oldJson = JSON.parse(data) as Array<hlDescriptorI>;

                const newJson = oldJson.map((o) => [o.wrapper, o.textContent, o.path, o.offset, o.length]);

                const newString = JSON.stringify(newJson);

                return newString;
            }
        }

        return "";
    }, [el]);

    const updateData = useCallback(() => {
        var newData = getHighlightingData();
        setHighlightingData(newData);

        if (props && props.onUpdated) {
            props.onUpdated(newData);
        }
    }, [setHighlightingData, getHighlightingData, props]);

    const clearHighlighting = () => {
        if (el.current) {
            removeHighlights(el.current);
            updateData();
        }
    };

    const applyHighlighting = useCallback(() => {
        if (el.current && selectedTag && highlightingEnabled) {
            doHighlight(el.current, false, {
                color: selectedTag.Colour,
                onAfterHighlight: (...e) => {
                    const spans = e[1] as Array<HTMLElement>;

                    for (const span of spans) {
                        if (selectedTag) {
                            span.setAttribute(dataHighlightTagId, selectedTag.ID.toString());

                            // add custom class if it's been set
                            if (props && props.highlightedClass) {
                                span.classList.add(props.highlightedClass);
                            }
                        }
                    }

                    updateData();

                    return true;
                },
            });
        }
    }, [el, highlightingEnabled, updateData, selectedTag, props]);

    const checkIfSnippetIsUsingTag = (t: SnippetHighlightingTag, element?: HTMLElement) => {
        const e = element ?? el.current;
        return e && e.querySelector(`[${dataHighlightTagId}="${t.ID}"]`) != null;
    };

    const convertJsStringToTypescriptObject = (str: string): string => {
        var json = JSON.parse(str) as StrongArray[];

        var newObjectJson: Array<hlDescriptorI> = json.map((x) => {
            return {
                wrapper: x[0],
                textContent: x[1],
                hlpaths: [] as number[],
                path: x[2],
                offset: x[3],
                length: x[4],
                color: "",
            };
        });

        return JSON.stringify(newObjectJson);
    };

    const setHighlighting = (json: string) => {
        if (el.current) {
            const newObjectString = convertJsStringToTypescriptObject(json);

            deserializeHighlights(el.current, newObjectString);
        }
    };

    const onHighlightingClick = useCallback(
        (e: MouseEvent) => {
            const span = e.target;

            if (span instanceof HTMLSpanElement) {
                if (highlightingEnabled) {
                    removeHighlights(span);
                    updateData();
                }
            }
        },
        [updateData, highlightingEnabled]
    );

    useEffect(() => {
        const current = el.current;

        if (current) {
            current.addEventListener("mouseup", applyHighlighting);
            current.addEventListener("touchend", applyHighlighting);
            current.addEventListener("click", onHighlightingClick);
        }

        return () => {
            if (current) {
                current.removeEventListener("mouseup", applyHighlighting);
                current.removeEventListener("touchend", applyHighlighting);
                current.removeEventListener("click", onHighlightingClick);
            }
        };
    }, [el, applyHighlighting, onHighlightingClick]);

    return {
        selectedTag,
        setSelectedTag,
        clearHighlighting,
        getHighlightingData,
        setHighlighting,
        tags,
        setTags,
        highlightingEnabled,
        setHighlightingEnabled,
        highlightingData,
        checkIfSnippetIsUsingTag,
    };
};
