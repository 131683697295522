import * as yup from "yup";
import { LessonTopicPageTextboxModel, LessonTopicPageTextboxModelValidationRules } from "./LessonTopicPageTextboxModel";

export interface LessonTopicPageTextboxCreateModel extends LessonTopicPageTextboxModel {
    LessonTopicPageID: number;
}

export const LessonTopicPageTextboxCreateModelValidationSchema = yup.object().shape<LessonTopicPageTextboxCreateModel>({
    IsImageSideBySide: LessonTopicPageTextboxModelValidationRules.IsImageSideBySide,
    Label: LessonTopicPageTextboxModelValidationRules.Label,
    IsCombinable: LessonTopicPageTextboxModelValidationRules.IsCombinable,
    IsCombinedFromAllPrevious: LessonTopicPageTextboxModelValidationRules.IsCombinedFromAllPrevious,
    IsCombinedFromCurrentTopic: LessonTopicPageTextboxModelValidationRules.IsCombinedFromCurrentTopic,
    IsFeed: LessonTopicPageTextboxModelValidationRules.IsFeed,
    LessonTopicPageID: yup.number().required(),
});
