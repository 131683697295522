import { FC, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { RouteTable } from "./Routes";
import { useGlobal, GlobalContext } from "./hooks/useGlobal";
import { Constants } from "./utils/Constants";
import { CSSTransition } from "react-transition-group";
import "bootstrap";
import "./css/app.scss";

// TODO: if we get bootstrap 5, remove jQuery
export const App: FC = () => {
    const { animationDuration } = Constants;

    const globalContext = useGlobal();

    useEffect(() => {
        // redraw on resize to fix items not be vertically centered when screen sizes change
        window.addEventListener("resize", () => {
            document.body.style.display = "inline-block";

            setTimeout(() => {
                document.body.style.display = "";
            }, 1);
        });

        // set CSS animation variable here so it's referenced in on place in JS
        document.documentElement.style.setProperty("--animation-duration", `${animationDuration}ms`);
    }, [animationDuration]);

    return (
        <GlobalContext.Provider value={globalContext}>
            <Router>
                {RouteTable.map(({ path, Component }) => (
                    <Route exact path={path} key={path}>
                        {({ match }) => {
                            return (
                                <CSSTransition classNames="trans" in={match != null} timeout={animationDuration} unmountOnExit>
                                    <Component {...match?.params} />
                                </CSSTransition>
                            );
                        }}
                    </Route>
                ))}
            </Router>
        </GlobalContext.Provider>
    );
};
