import { yupResolver } from "@hookform/resolvers";
import { FC, Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import { LessonTopicPageCreateModelValidationSchema } from "../../../models/crud/lessontopicpages/LessonTopicPageCreateModel";
import { LessonTopicPageModel } from "../../../models/crud/lessontopicpages/LessonTopicPageModel";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { nameof } from "../../../utils/Helpers";
import { Checkbox } from "../../fields/Checkbox";
import { Hidden } from "../../fields/Hidden";
import { Textbox } from "../../fields/Textbox";
import { Loading } from "../../Loading";

type Props = {
    topicId: number;
};

export const AddPageModal: FC<Props> = (props: Props) => {
    const { topicId } = props;

    const [modal, setModal] = useState(false);

    const nameName = nameof<LessonTopicPageModel>("Name");
    const nameIsAssessable = nameof<LessonTopicPageModel>("IsAssessable");
    const nameTopicId = nameof<LessonTopicPageModel>("LessonTopicID");

    const { addPage } = useContext(LessonBuilderContext);

    const toggleModal = () => setModal(!modal);

    const { setLoading, confirmServerError, loading, handleFormResponse } = useFormState();

    const { errors, register, handleSubmit, setError } = useForm<LessonTopicPageModel>({
        resolver: yupResolver(LessonTopicPageCreateModelValidationSchema),
    });

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        LessonBuilderService.createPage(model)
            .then((x) => {
                if (x.Success) {
                    setModal(false);
                    addPage(x.Data);
                }

                handleFormResponse(x.Success, x.Errors, setError);
            })
            .catch(confirmServerError);
    });

    return (
        <Fragment>
            <Button block onClick={toggleModal}>
                Add page
            </Button>

            <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
                {loading ? (
                    <Loading />
                ) : (
                    <form onSubmit={onSubmit}>
                        <ModalHeader>Add page</ModalHeader>
                        <ModalBody>
                            <Textbox name={nameName} errors={errors} register={register} label="Name" />
                            <Checkbox
                                name={nameIsAssessable}
                                errors={errors}
                                register={register}
                                label="Textboxes on this page can be graded in checkpoint review"
                            />
                            <Hidden name={nameTopicId} register={register} value={topicId} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary">Add</Button>
                            <Button color="secondary" type="button" onClick={() => setModal(false)}>
                                Close
                            </Button>
                        </ModalFooter>
                    </form>
                )}
            </Modal>
        </Fragment>
    );
};
