import * as yup from "yup";
import { LessonTopicPageVideoModelValidationRules, LessonTopicPageVideoModel } from "./LessonTopicPageVideoModel";

export interface LessonTopicPageVideoCreateModel extends LessonTopicPageVideoModel {
    LessonTopicPageID: number;
}

export const LessonTopicPageVideoCreateModelValidationSchema = yup.object().shape<LessonTopicPageVideoCreateModel>({
    LessonTopicPageID: yup.number().required(),
    VideoID: LessonTopicPageVideoModelValidationRules.VideoID,
});
