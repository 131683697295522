import { WrotevoteWelcomePage } from "./pages/wrotevote/WrotevoteWelcomePage";
import { WrotevoteComparePage } from "./pages/wrotevote/WrotevoteComparePage";
import { WrotevoteLeaderboardPage } from "./pages/wrotevote/WrotevoteLeaderboardPage";
import { LessonBuilderPage } from "./pages/lessonbuilder/LessonBuilderPage";
import { ImagesPage } from "./pages/ckeditor/ImagesPage";
import { HomePage } from "./pages/HomePage";
import { FC } from "react";

export const RouteList = {
    WrotevoteWelcome: (id?: string | null) => `/wrotevote/${id ?? ":id"}/welcome`,
    WrotevoteCompare: (id?: string | null) => `/wrotevote/${id ?? ":id"}/compare`,
    WrotevoteLeaderboard: (id?: string | null) => `/wrotevote/${id ?? ":id"}/leaderboard`,
    LessonBuilderPage: (id?: string | null) => `/lessonbuilder/page/${id ?? ":id"}`,
    ImagesPage: "/images",
    Home: "/",
};

interface IRouteTableItem {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: FC<any>;
}

export const RouteTable: Array<IRouteTableItem> = [
    { path: RouteList.WrotevoteWelcome(), Component: WrotevoteWelcomePage },
    { path: RouteList.WrotevoteCompare(), Component: WrotevoteComparePage },
    { path: RouteList.WrotevoteLeaderboard(), Component: WrotevoteLeaderboardPage },
    { path: RouteList.LessonBuilderPage(), Component: LessonBuilderPage },
    { path: RouteList.ImagesPage, Component: ImagesPage },
    { path: RouteList.Home, Component: HomePage },
];
