import { yupResolver } from "@hookform/resolvers";
import { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useFormState } from "../../../hooks/useFormState";
import { LessonBuilderContext } from "../../../hooks/useLessonBuilder";
import {
    LessonTopicPageImageCreateModel,
    LessonTopicPageImageCreateModelValidationSchema,
} from "../../../models/crud/lessontopicpageimages/LessonTopicPageImageCreateModel";
import { nameof } from "../../../utils/Helpers";
import { LessonBuilderService } from "../../../services/LessonBuilderService";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Loading } from "../../Loading";
import { Hidden } from "../../fields/Hidden";
import { FieldLessonTopicPageImageCaption } from "../../fields/lesson-topic-page-images/FieldLessonTopicPageImageCaption";
import { FieldLessonTopicPageImageImageID } from "../../fields/lesson-topic-page-images/FieldLessonTopicPageImageImageID";
import { FieldLessonTopicPageImageSourceUrl } from "../../fields/lesson-topic-page-images/FieldLessonTopicPageImageSourceUrl";

const nameLessonTopicPageID = nameof<LessonTopicPageImageCreateModel>("LessonTopicPageID");

export const AddImageModal = () => {
    const [modal, setModal] = useState(false);

    const { addPageItem, page } = useContext(LessonBuilderContext);
    const { setLoading, confirmServerError, loading } = useFormState();

    const { register, handleSubmit, errors, setValue } = useForm<LessonTopicPageImageCreateModel>({
        resolver: yupResolver(LessonTopicPageImageCreateModelValidationSchema),
    });

    const toggleModal = () => setModal(!modal);

    const onSubmit = handleSubmit(model => {
        setLoading(true);

        LessonBuilderService.addImage(model)
            .then(x => {
                addPageItem(x.Data);
                setModal(false);
                setLoading(false);
            })
            .catch(confirmServerError);
    });

    return (
        <Fragment>
            <Button type="button" onClick={toggleModal}>
                Add image
            </Button>

            <Modal external={false} isOpen={modal} toggle={toggleModal} centered={true}>
                {loading ? (
                    <Loading />
                ) : (
                    page && (
                        <form onSubmit={onSubmit}>
                            <ModalHeader>Add image</ModalHeader>
                            <ModalBody>
                                <Hidden name={nameLessonTopicPageID} value={page.ID} register={register} />
                                <FieldLessonTopicPageImageCaption register={register} errors={errors} />
                                <FieldLessonTopicPageImageSourceUrl register={register} errors={errors} />
                                <FieldLessonTopicPageImageImageID
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary">Add</Button>
                                <Button type="button" onClick={toggleModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </form>
                    )
                )}
            </Modal>
        </Fragment>
    );
};
