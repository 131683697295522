import { FC } from "react";

type Props = {
    voteCount: number;
    votePercent: number;
};

export const WrotevoteVotePercent: FC<Props> = (props: Props) => {
    const { voteCount, votePercent } = props;

    return (
        <div>
            {voteCount} vote{voteCount !== 1 && "s"} / {votePercent}%
        </div>
    );
};
